import { useCallback } from 'react';

import { useAIAnswerContext } from '../../../../ai-answer-dialog/ai-answer-context';
import { NLPSearchResultFormat } from '../../../../ai-answer-dialog/types';
import { fetchAggResponse } from '../../../../common';
import {
	CreateDefinitionMutation,
	type CreateDefinitionMutationType,
	type CreateDefinitionMutationVariables,
} from '../../gql/create-definition-mutation';
import type {
	CreateDefinitionFunctionArgs,
	CreateDefinitionFunctionType,
	CuratedDefinitionScopeConfigType,
} from '../types';

import { getEntityIdInScope, mapCreatedDefinitionToNLPSearchType } from './utils';

type CreateDefinitionSupplierType = {
	createDefinition: CreateDefinitionFunctionType;
};

export const useCreateDefinitionSupplier = ({
	curatedDefinitionScopeConfig,
}: {
	curatedDefinitionScopeConfig: CuratedDefinitionScopeConfigType;
}): CreateDefinitionSupplierType => {
	const { commonAttributes } = useAIAnswerContext();

	const createDefinition = useCallback(
		async ({
			keyPhrase,
			editedDefinition,
			scope,
			referenceContentId,
			referenceUrl,
		}: CreateDefinitionFunctionArgs) => {
			const { cloudId } = commonAttributes;
			const { workspaceId, productScopes } = curatedDefinitionScopeConfig;
			const entityIdInScope = getEntityIdInScope({
				selectedScope: scope,
				cloudId,
				productScopes,
			});

			return fetchAggResponse<CreateDefinitionMutationVariables, CreateDefinitionMutationType>({
				graphQuery: CreateDefinitionMutation,
				variables: {
					workspaceId: workspaceId || '',
					keyPhrase,
					editedDefinition,
					scope,
					entityIdInScope,
					referenceContentId,
					referenceUrl,
				},
			}).then((response) => {
				if (response?.data?.knowledgeDiscovery.createDefinition?.success) {
					const definitionDetails =
						response.data.knowledgeDiscovery.createDefinition.definitionDetails;
					return (
						(definitionDetails && mapCreatedDefinitionToNLPSearchType(definitionDetails)) || {
							nlpResult: '',
							format: NLPSearchResultFormat.PLAINTEXT,
							uniqueSources: [],
							errorState: null,
							disclaimer: null,
						}
					);
				} else {
					throw new Error('createDefinition mutation unsuccessful');
				}
			});
		},
		[commonAttributes, curatedDefinitionScopeConfig],
	);

	return { createDefinition };
};
