// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const AIAnswerBodyWrapper = styled.div<{ isReadingAids?: boolean }>((props) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: props.isReadingAids
		? `${token('space.075', '6px')} ${token('space.300', '24px')} ${token('space.050', '4px')}`
		: `${token('space.100')} ${token('space.300')} 0px ${token('space.300')}`,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerErrorWrapper = styled.div({
	paddingRight: `${token('space.300', '24px')}`,
});
