import React from 'react';

import Icon from '@atlaskit/icon';
import type { CustomGlyphProps } from '@atlaskit/icon/types';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const SearchIconSvg = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7 2.5C4.51472 2.5 2.5 4.51472 2.5 7C2.5 9.48528 4.51472 11.5 7 11.5C8.24285 11.5 9.36682 10.9971 10.182 10.182C10.9971 9.36682 11.5 8.24285 11.5 7C11.5 4.51472 9.48528 2.5 7 2.5ZM1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.38622 12.5292 9.66348 11.7399 10.6792L15.0303 13.9697L13.9697 15.0303L10.6792 11.7399C9.66348 12.5292 8.38622 13 7 13C3.68629 13 1 10.3137 1 7Z"
			fill="currentColor"
		/>
	</svg>
);

export const SearchIcon = ({ size = 'medium' }: { size?: 'small' | 'medium' | 'large' }) => (
	<Icon size={size} label="" glyph={SearchIconSvg} primaryColor={token('color.icon', N500)} />
);
