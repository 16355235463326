export const ATLASSIAN_INTELLIGENCE_TRUST_LINK =
	'https://www.atlassian.com/trust/atlassian-intelligence';

export const ATLASSIAN_ACCEPTABLE_USE_POLICY_LINK =
	'https://www.atlassian.com/legal/acceptable-use-policy';

export const ATLASSIAN_AI_TROUBLESHOOT_LINK =
	'https://support.atlassian.com/organization-administration/docs/troubleshooting-for-atlassian-intelligence/';

export const ATLASSIAN_ROVO_LINK = 'https://www.atlassian.com/software/rovo';

export const FEEDBACK_CONTEXT_CF = 'customfield_10047';
export const FEEDBACK_ENTRYPOINT_ID_DEFINITIONS = '3655ce1d-16b8-4630-9e93-6c39069625fa';
