import React from 'react';

import { type MessageDescriptor, useIntl } from 'react-intl-next';

import Avatar, { type AppearanceType } from '@atlaskit/avatar';

import { messages } from '../../../../../messages';
import { useAIAnswerContext } from '../../../../ai-answer-context';
import {
	onWhoPrimaryCoordinationPillClick,
	onWhoPrimaryCoordinationPillContextMenuClick,
} from '../../../../analytics';
import {
	NLPSearchResultContainerDataTypeContainerType,
	type NLPSearchWhoResultCollaboratorDataType,
	type NLPSearchWhoResultContainerDataType,
	type NLPSearchWhoResultTeamDataType,
} from '../../../../types';

import {
	AIWhoAnswerSectionHeader,
	AIWhoAnswerSectionResult,
	AIWhoAnswerSectionResultInfoHeader,
	AIWhoAnswerSectionResultInfoWrapper,
	AIWhoAnswerSectionResultMetadata,
	AIWhoAnswerSectionResultsWrapper,
	AIWhoAnswerSectionWrapper,
} from './styled';

const WHO_SECTION_MAX_ITEMS = 3;

type AIAnswerWhoSectionProps = {
	sectionId: string;
	sectionHeader: MessageDescriptor;
	sectionResults:
		| Array<
				| NLPSearchWhoResultContainerDataType
				| NLPSearchWhoResultCollaboratorDataType
				| NLPSearchWhoResultTeamDataType
		  >
		| undefined;
	avatarAppearance: AppearanceType;
};

export const AIAnswerWhoSection = ({
	sectionId,
	sectionHeader,
	sectionResults,
	avatarAppearance,
}: AIAnswerWhoSectionProps) => {
	const { formatMessage } = useIntl();
	const { commonAttributes, fireAnalyticsEvent, onNavigate } = useAIAnswerContext();

	const getSectionResultMetadata = (
		sectionResult:
			| NLPSearchWhoResultContainerDataType
			| NLPSearchWhoResultCollaboratorDataType
			| NLPSearchWhoResultTeamDataType,
	): string => {
		if ('containerType' in sectionResult) {
			switch (sectionResult.containerType) {
				case NLPSearchResultContainerDataTypeContainerType.ConfluenceSpace:
					return 'Confluence';
				case NLPSearchResultContainerDataTypeContainerType.JiraProject:
					return 'Jira Software';
				default:
					return '';
			}
		}
		if ('title' in sectionResult) {
			return sectionResult.title || '';
		}
		if ('memberCount' in sectionResult) {
			return formatMessage(messages.ai_answer_who_question_team_section_member_count, {
				count: sectionResult.memberCount,
			});
		}
		return '';
	};

	return sectionResults && sectionResults.length ? (
		<AIWhoAnswerSectionWrapper>
			<AIWhoAnswerSectionHeader>{formatMessage(sectionHeader)}</AIWhoAnswerSectionHeader>
			<AIWhoAnswerSectionResultsWrapper>
				{sectionResults.slice(0, WHO_SECTION_MAX_ITEMS).map((sectionResult) => (
					<AIWhoAnswerSectionResult
						key={sectionResult.name}
						href={sectionResult.url || undefined}
						onClick={(event) => {
							onNavigate(event);
							fireAnalyticsEvent(
								onWhoPrimaryCoordinationPillClick({
									...commonAttributes,
									resultSection: sectionId,
									resultTitle: sectionResult.name,
								}),
							);
						}}
						onContextMenu={() => {
							fireAnalyticsEvent(
								onWhoPrimaryCoordinationPillContextMenuClick({
									...commonAttributes,
									resultSection: sectionId,
									resultTitle: sectionResult.name,
								}),
							);
						}}
					>
						<Avatar
							appearance={avatarAppearance}
							src={
								(sectionResult as NLPSearchWhoResultContainerDataType).iconUrl ||
								(sectionResult as NLPSearchWhoResultCollaboratorDataType).picture ||
								(sectionResult as NLPSearchWhoResultTeamDataType).avatarUrl ||
								undefined
							}
							size="medium"
						/>
						<AIWhoAnswerSectionResultInfoWrapper>
							<AIWhoAnswerSectionResultInfoHeader>
								{sectionResult.name}
							</AIWhoAnswerSectionResultInfoHeader>
							<AIWhoAnswerSectionResultMetadata>
								{getSectionResultMetadata(sectionResult)}
							</AIWhoAnswerSectionResultMetadata>
						</AIWhoAnswerSectionResultInfoWrapper>
					</AIWhoAnswerSectionResult>
				))}
			</AIWhoAnswerSectionResultsWrapper>
		</AIWhoAnswerSectionWrapper>
	) : null;
};
