import { type EditedDefinitionScope } from '../../curated/types';

export interface CreateDefinitionMutationErrorType {
	message: string | null;
}

export interface CreateDefinitionDetailsType {
	keyPhrase: string;
	definition: string;
	referenceUrl?: string | null;
	confluenceEntity: {
		id: string;
		type: string | null;
		title: string | null;
		space: {
			name: string | null;
		} | null;
	} | null;
	editor: {
		name: string;
		accountId: string;
	} | null;
}

export interface CreateDefinitionType {
	success: boolean;
	errors: CreateDefinitionMutationErrorType[];
	definitionDetails: CreateDefinitionDetailsType | null;
}

export interface CreateDefinitionMutationType {
	knowledgeDiscovery: {
		createDefinition: CreateDefinitionType | null;
	};
}

export interface CreateDefinitionMutationVariables {
	workspaceId: string;
	keyPhrase: string;
	editedDefinition: string;
	scope: EditedDefinitionScope;
	entityIdInScope: string;
	referenceContentId: string | null;
	referenceUrl: string | null;
}

export const CreateDefinitionMutation = `
	mutation CreateDefinitionMutation(
		$workspaceId: String!
		$keyPhrase: String!
		$editedDefinition: String!
		$scope: KnowledgeDiscoveryDefinitionScope!
		$entityIdInScope: String!
		$referenceContentId: String
		$referenceUrl: String
		) {
		knowledgeDiscovery {
			createDefinition(
				input: {
				workspaceId: $workspaceId
				keyPhrase: $keyPhrase
				definition: $editedDefinition
				scope: $scope
				entityIdInScope: $entityIdInScope
				referenceContentId: $referenceContentId
				referenceUrl: $referenceUrl
				}
			) @optIn(to: "KnowledgeDiscovery Create definition") {
			success
			errors {
				message
			}
			definitionDetails {
				keyPhrase
				definition
				editor {
					... on User {
						name
						accountId
					}
				}
				referenceUrl
				confluenceEntity {
					... on ConfluencePage {
					id
					type
					title
					space {
						name
					}
					}
					... on ConfluenceBlogPost {
					id
					type
					title
					space {
						name
					}
					}
				}
			}
		}
	}
  }
`;
