import React, { type PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl-next';

import EditorExpandIcon from '@atlaskit/icon/core/migration/grow-horizontal--editor-expand';
import EditorCollapseIcon from '@atlaskit/icon/core/migration/shrink-horizontal--editor-collapse';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

import { messages } from './messages';
import { Blur, ButtonIcon, ToggleButton } from './styled';

const containerStyles = xcss({
	overflow: 'hidden',
	position: 'relative',
});

const expandedStyles = xcss({
	height: 'auto',
});

const collapsedStyles = xcss({
	height: '150px',
});

const pointerCursorStyles = xcss({
	cursor: 'pointer',
});

const titleContainer = xcss({
	color: 'color.text.subtlest',
	fontSize: '12px',
	fontWeight: 600,
	lineHeight: '16px',
	letterSpacing: 0,
	paddingTop: 'space.250',
	paddingRight: 'space.300',
	paddingBottom: 'space.0',
	paddingLeft: 'space.300',
});

type CollapseProps = {
	collapsed: boolean;
	isLoading: boolean;
	toggleCollapsedState?: () => void;
	expandWhenClickedAnywhere?: boolean;
	isStreaming?: boolean;
	disableCollapse?: boolean;
	disableTitle?: boolean;
	callbacks?: {
		// called when card is rendered in the expanded state due to the answer being short
		onExpandedByDefault?: () => void;
		onExpandClicked?: () => void;
		onCollapseClicked?: () => void;
	};
};

export const Collapse = ({
	children,
	collapsed,
	isLoading,
	toggleCollapsedState = () => {},
	disableTitle = false,
	expandWhenClickedAnywhere = false,
	isStreaming = false,
	disableCollapse = false,
	callbacks,
}: PropsWithChildren<CollapseProps>) => {
	const [shouldStayExpanded, setShouldStayExpanded] = useState(false);
	const threshold = 150;

	const collapseableContainerRef = useCallback(
		(node: HTMLInputElement | null) => {
			if (!node || disableCollapse) {
				return;
			}

			const resizeObserver = new ResizeObserver(() => {
				const childHeight = node.getBoundingClientRect().height;
				if (isStreaming || isLoading || !Boolean(childHeight)) {
					return;
				}

				// checking if the length of the answer is smaller than the collapse threshold
				if (childHeight <= threshold && !shouldStayExpanded) {
					setShouldStayExpanded(true);
				} else {
					setShouldStayExpanded(false);
				}
			});

			resizeObserver.observe(node);
		},
		// make sure the dependancy array is empty,
		// this is to ensure the resizeObserver is registered just once on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		/**
		 * if the answer has finished isLoading and the container is too small to collapse,
		 * toggle collapsed state to false
		 * this helps external components watching the boolean to know the actual state
		 */
		if ((shouldStayExpanded && !isLoading && !isStreaming && collapsed) || disableCollapse) {
			toggleCollapsedState();
			callbacks?.onExpandedByDefault?.();
		}
	}, [
		shouldStayExpanded,
		isLoading,
		isStreaming,
		collapsed,
		callbacks,
		toggleCollapsedState,
		disableCollapse,
	]);

	const { formatMessage } = useIntl();
	const tooltipText = formatMessage(
		collapsed ? messages.ai_answer_expand_tooltip : messages.ai_answer_collapse_tooltip,
	);

	// allow whole container clicks to expand when enabled and the answer has finished streaming
	const onContainerClickHandler = () => {
		if (expandWhenClickedAnywhere && collapsed) {
			callbacks?.onExpandClicked?.();
			toggleCollapsedState();
		}
	};

	const onClickHandler = () => {
		if (collapsed) {
			callbacks?.onExpandClicked?.();
		} else {
			callbacks?.onCollapseClicked?.();
		}
		toggleCollapsedState();
	};

	/**
	 * render the non collapsable version
	 * - child container's height is lesser than the threshold
	 * - collapsed card is externally disabled, ex: for a person card
	 */
	if (shouldStayExpanded || disableCollapse) {
		return (
			<Box xcss={[containerStyles, expandedStyles]}>
				<div>
					{!isLoading && (
						<Inline alignBlock="center" xcss={titleContainer}>
							{!disableTitle && <div>{formatMessage(messages.title_label)}</div>}
						</Inline>
					)}
					{children}
				</div>
			</Box>
		);
	}

	return (
		<Box
			xcss={[
				containerStyles,
				collapsed ? collapsedStyles : expandedStyles,
				expandWhenClickedAnywhere && collapsed && pointerCursorStyles,
			]}
			onClick={onContainerClickHandler}
		>
			<Inline alignBlock="center" spread={'space-between'} xcss={titleContainer}>
				{!disableTitle && <div>{formatMessage(messages.title_label)}</div>}
				<div>
					{!isLoading && (
						<Tooltip position={'bottom'} content={tooltipText}>
							<ToggleButton onClick={onClickHandler} data-testid="expand-collapse-toggle">
								<ButtonIcon>
									{collapsed ? (
										<EditorExpandIcon
											color="currentColor"
											spacing="spacious"
											label={formatMessage(messages.expand_label)}
										/>
									) : (
										<EditorCollapseIcon
											color="currentColor"
											spacing="spacious"
											label={formatMessage(messages.collapse_label)}
										/>
									)}
								</ButtonIcon>
							</ToggleButton>
						</Tooltip>
					)}
				</div>
			</Inline>
			<div ref={collapseableContainerRef}>{children}</div>
			{collapsed && <Blur></Blur>}
		</Box>
	);
};
