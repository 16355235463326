import React from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import Blog24Icon from '@atlaskit/icon-object/glyph/blog/24';
import Page24Icon from '@atlaskit/icon-object/glyph/page/24';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { type CuratedDefinitionSourceType } from '../types';

import { messages } from './messages';

const SourceOptionWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: 'space.150',
	boxSizing: 'border-box',
	cursor: 'pointer',
	':hover': {
		backgroundColor: 'elevation.surface.hovered',
		cursor: 'pointer',
	},
});

const SourceOptionTitleStyles = xcss({
	color: 'color.text',
	font: token('font.body'),
});

const SourceOptionMetadataStyles = xcss({
	display: 'flex',
	color: 'color.text.subtlest',
	font: token('font.body.small'),
});

const SourceOptionMetatadataSeparatorStyles = xcss({
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

type SourceOptionProps = {
	source: CuratedDefinitionSourceType;
	canClear: boolean;
	onClickSource?: () => void;
	onRemoveSource?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const SourceOption = ({
	source,
	canClear,
	onClickSource,
	onRemoveSource,
}: SourceOptionProps) => {
	const { formatDate, formatMessage } = useIntl();

	return (
		<Box xcss={SourceOptionWrapperStyles} onClick={onClickSource}>
			<Flex alignItems="center" gap="space.150">
				{source.type === 'blogpost' ? (
					// TODO: https://product-fabric.atlassian.net/browse/DSP-20735
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<Blog24Icon label="" />
				) : (
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<Page24Icon label="" />
				)}
				<Flex direction="column">
					<Box xcss={SourceOptionTitleStyles}>{source.title}</Box>
					<Box xcss={SourceOptionMetadataStyles}>
						<Text>{source.container}</Text>
						{source.lastModified && <Box xcss={SourceOptionMetatadataSeparatorStyles}>{`•`}</Box>}
						{source.lastModified && (
							<Text>{`${formatMessage(messages.edit_definition_source_option_metadata_updated_text)} ${formatDate(
								source.lastModified,
								{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
								},
							)}`}</Text>
						)}
					</Box>
				</Flex>
			</Flex>
			{canClear ? (
				<IconButton
					testId={`remove-source-${source.id}`}
					appearance="subtle"
					onClick={onRemoveSource}
					label="remove-source-option-button"
					icon={EditorCloseIcon}
				/>
			) : (
				<div></div>
			)}
		</Box>
	);
};
