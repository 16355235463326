import { NLPSearchResultFormat } from '../types';

import { getTypedJSONResultDataAssistanceService } from './getTypedJSONResultData';

export const getNumAddtlWhoMatches = ({
	answerString,
	answerFormat,
}: {
	answerString: string;
	answerFormat: NLPSearchResultFormat | null;
}): number => {
	if (answerString && answerFormat === NLPSearchResultFormat.JSON) {
		const { parsedResult } = getTypedJSONResultDataAssistanceService(answerString);
		return Array.isArray(parsedResult) ? parsedResult.length - 1 : 0;
	}
	return 0;
};
