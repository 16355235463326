import { type ConfluenceScopeType, type JiraScopeType } from '../../curated/types';
import { type DefinitionConfluenceScope, type DefinitionJiraScope } from '../../gql/types';

type ProductScopeType = {
	confluenceScopeId?: DefinitionConfluenceScope;
	jiraScopeId?: DefinitionJiraScope;
};

export const getProductScope = (
	productScopes: ConfluenceScopeType | JiraScopeType,
): ProductScopeType => {
	if ('contentARI' in productScopes) {
		return {
			confluenceScopeId: {
				contentId: productScopes.contentARI,
				spaceId: productScopes.spaceARI,
			},
		};
	} else {
		return {
			jiraScopeId: {
				projectId: productScopes.projectARI,
			},
		};
	}
};
