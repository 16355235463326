import React, { useState } from 'react';

import { useIntl } from 'react-intl-next';

import MediaServicesAnnotateIcon from '@atlaskit/icon/glyph/media-services/annotate';
import InfoIcon from '@atlaskit/icon/utility/migration/information--info';
import Link from '@atlaskit/link';
import Lozenge from '@atlaskit/lozenge';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import ProfileClient from '@atlaskit/profilecard/client';
import ProfileCardTrigger from '@atlaskit/profilecard/user';
import { N40A, N700, N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { useAIAnswerContext } from '../../../ai-answer-dialog/ai-answer-context';
import { AiBrand } from '../../../constants';
import { onCuratedDefinitionSeeEditHistoryButtonClicked } from '../analytics';
import { ATLASSIAN_INTELLIGENCE_TRUST_LINK, ATLASSIAN_ROVO_LINK } from '../common/constants';
import { AtlassianIntelligenceIcon } from '../common/icons/atlassian-intelligence-icon';
import { RovoIcon } from '../common/icons/rovo-icon';

import { messages } from './messages';

const AIFooterStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
	paddingLeft: 'space.250',
	paddingRight: 'space.250',
	borderTop: `1px solid ${token('color.border', N40A)}`,
	width: '100%',
});

const ContentMayVaryWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.050',
	color: 'color.text.subtlest',
	fontSize: '11px',
	fontWeight: 400,
});

const BrandWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	color: 'color.text.subtlest',
	fontSize: '11px',
	fontWeight: 400,
});

export const AIDefinitionFooterSection = () => {
	const { formatMessage } = useIntl();
	const { brand } = useAIAnswerContext();
	return (
		<Box xcss={AIFooterStyles}>
			<Box xcss={ContentMayVaryWrapperStyles}>
				{brand === AiBrand.ROVO && fg('platform_search_ai_rovo_beta_label_off') ? null : (
					<Lozenge>{formatMessage(messages.definition_footer_beta_status_label)}</Lozenge>
				)}
				<InfoIcon label="" color={token('color.icon.subtle', '#626F86')} LEGACY_size="small" />
				<Link
					rel="noopener noreferrer"
					appearance="subtle"
					href={ATLASSIAN_INTELLIGENCE_TRUST_LINK}
				>
					{formatMessage(messages.definition_footer_content_quality_short)}
				</Link>
			</Box>
			<Box xcss={BrandWrapperStyles}>
				{brand === AiBrand.ROVO ? (
					<Flex alignItems="center" gap="space.025">
						<Link rel="noopener noreferrer" appearance="subtle" href={ATLASSIAN_ROVO_LINK}>
							Rovo
						</Link>
						<RovoIcon label="" />
					</Flex>
				) : (
					<Flex alignItems="center" gap="space.025">
						{formatMessage(messages.definition_footer_powered_by_AI)}
						<AtlassianIntelligenceIcon greyed size="large" />
					</Flex>
				)}
			</Box>
		</Box>
	);
};

const EditedByFooterStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingLeft: 'space.250',
	paddingRight: 'space.250',
	borderTop: `1px solid ${token('color.border', N40A)}`,
});

const EditedByWrapper = xcss({
	display: 'flex',
	background: token('color.background.neutral', N900),
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	borderRadius: '24px',
	marginLeft: 'space.050',
	cursor: 'pointer',
	':hover': {
		background: token('color.background.neutral.hovered', N700),
	},
});

const ActiveEditedByWrapper = xcss({
	display: 'flex',
	background: token('color.background.neutral.hovered', N700),
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	borderRadius: '24px',
	marginLeft: 'space.050',
	cursor: 'pointer',
});

const EditHistoryWrapper = xcss({
	display: 'flex',
	fontSize: '11px',
	color: 'color.link',
	paddingLeft: 'space.300',
	cursor: 'pointer',
	':hover': {
		textDecoration: 'underline',
	},
});

type EditedDefinitionFooterSectionProps = {
	setShowEditHistoryModal: (showModal: boolean) => void;
};

const DEFAULT_GRAPHQL_DIRECTORY_ENDPOINT = '/gateway/api/directory/graphql';

const profileClient = new ProfileClient({
	url: DEFAULT_GRAPHQL_DIRECTORY_ENDPOINT,
});

export const EditedDefinitionFooterSection = ({
	setShowEditHistoryModal,
}: EditedDefinitionFooterSectionProps) => {
	const { formatMessage } = useIntl();
	const [editorActive, setEditorActive] = useState<boolean>(false);
	const { answerEditor, commonAttributes, fireAnalyticsEvent } = useAIAnswerContext();
	const { cloudId } = commonAttributes;

	if (!answerEditor) {
		return null; // This line should not be reachable since the component is only rendered when answerEditor is defined
	}

	return (
		<Box xcss={EditedByFooterStyles}>
			<Flex gap="space.100" alignItems="start" direction="column">
				<Flex alignItems="center">
					{
						// TODO: https://product-fabric.atlassian.net/browse/DSP-20838
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						<MediaServicesAnnotateIcon label="" />
					}
					<Text>{formatMessage(messages.definition_footer_defined_by_text)}</Text>
					<ProfileCardTrigger
						userId={answerEditor.id}
						cloudId={cloudId}
						resourceClient={profileClient}
						trigger="click"
						onVisibilityChange={setEditorActive}
					>
						<Box xcss={editorActive ? ActiveEditedByWrapper : EditedByWrapper}>
							@{answerEditor.name}
						</Box>
					</ProfileCardTrigger>
				</Flex>
				<Box
					xcss={EditHistoryWrapper}
					onClick={() => {
						fireAnalyticsEvent(onCuratedDefinitionSeeEditHistoryButtonClicked(commonAttributes));
						setShowEditHistoryModal(true);
					}}
				>
					{formatMessage(messages.definition_footer_see_edit_history)}
				</Box>
			</Flex>
		</Box>
	);
};
