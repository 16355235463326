import React, { type PropsWithChildren, useEffect, useState } from 'react';

import { IntlProvider, useIntl } from 'react-intl-next';

import type { FetchLanguageFile } from './types';
import { getCodesFromLocale } from './utils';

export function useFetchMessagesByLocale(locale: string, fetchLanguageFile: FetchLanguageFile) {
	const [messages, setMessages] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function startToFetchMessagesByLocale() {
			const { language, country } = getCodesFromLocale(locale);

			// don't load English messages
			if (language === 'en') {
				return;
			}

			try {
				setIsLoading(true);
				const finalLocale = country ? `${language}-${country}` : language;
				const languageFile = await fetchLanguageFile(finalLocale);
				const newMessages = languageFile.default || languageFile;
				setMessages(newMessages);
			} catch (e) {
				// fail to download message and do nothing because UI will use default language (English) to render
			} finally {
				setIsLoading(false);
			}
		}

		startToFetchMessagesByLocale();
	}, [fetchLanguageFile, locale]);

	return { messages, isLoading };
}

const WithMessages = ({
	children,
	fetchLanguageFile,
}: PropsWithChildren<{ fetchLanguageFile: FetchLanguageFile }>) => {
	const intl = useIntl();
	const { messages } = useFetchMessagesByLocale(intl.locale, fetchLanguageFile);
	return (
		<IntlProvider messages={messages} locale={intl.locale}>
			{children}
		</IntlProvider>
	);
};

export function WithCustomIntlProvider<T extends {}>(
	Component: React.ComponentType<T> | React.FunctionComponent<T>,
	fetchLanguageFile: FetchLanguageFile,
): React.FunctionComponent<T> {
	return (props: T) => {
		return (
			<WithMessages fetchLanguageFile={fetchLanguageFile}>
				<Component {...props} />
			</WithMessages>
		);
	};
}
