import React, { Fragment } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { AITopicsByPerson } from '../../../../../ai-topics';
import { messages } from '../../../../../messages';
import { useAIAnswerContext } from '../../../../ai-answer-context';
import type { NLPSearchWhoResultType } from '../../../../types';
import { getTypedJSONResultDataAssistanceService } from '../../../../utils/getTypedJSONResultData';
import { RecentWorkList } from '../recent-work-list';
import { ProfileSection } from '../user-profile-section';

import { AIAnswerWhoSection } from './who-result-section';

export const AIAnswerWhoResult = () => {
	const { commonAttributes, isAdminHubAIEnabled } = useAIAnswerContext();
	const { answerString, cloudId, sessionId } = commonAttributes;

	const aiTopicsEnabled =
		isAdminHubAIEnabled &&
		(fg('ai_topics_surface_person_knowledge_card') || fg('ai_topics_surface_person_card_shadow'));

	// answerString should have at least one result or else <AIAnswerError /> will be rendered
	const whoResult: NLPSearchWhoResultType = {
		user_metadata: getTypedJSONResultDataAssistanceService(answerString).parsedResult,
	} as NLPSearchWhoResultType;

	const userResults = whoResult.user_metadata;
	// BE will always return first object as the primary user result
	const primaryUser = userResults[0];

	const frequentLocationsSection = (
		<AIAnswerWhoSection
			sectionId="frequent_locations"
			sectionHeader={messages.ai_answer_who_questions_frequent_locations_header}
			sectionResults={primaryUser.containers}
			avatarAppearance="square"
		/>
	);

	return (
		<Fragment>
			<ProfileSection
				accountId={primaryUser.account_id}
				name={primaryUser.name}
				email={primaryUser.email}
				avatarUrl={primaryUser.avatar_url}
				profileUrl={primaryUser.profile_url}
				jobTitle={primaryUser.job_title}
				department={primaryUser.department}
				location={primaryUser.location}
				time={primaryUser.time}
			/>
			{aiTopicsEnabled ? (
				<AITopicsByPerson
					cloudId={cloudId}
					userId={primaryUser.account_id ?? ''}
					userName={primaryUser.name}
					sessionId={sessionId}
					fallbackSection={frequentLocationsSection}
				/>
			) : (
				<Fragment>{frequentLocationsSection}</Fragment>
			)}
			<AIAnswerWhoSection
				sectionId="frequent_collaborators"
				sectionHeader={messages.ai_answer_who_questions_frequent_collaborators_header}
				sectionResults={primaryUser.collaborators?.slice(0, 3)}
				avatarAppearance="circle"
			/>
			<AIAnswerWhoSection
				sectionId="teams"
				sectionHeader={messages.ai_answer_who_questions_teams_header}
				sectionResults={primaryUser.teams}
				avatarAppearance="square"
			/>
			{primaryUser.recentActivity && (
				<RecentWorkList
					name={primaryUser.name}
					activities={primaryUser.recentActivity}
					viewAllUrl={primaryUser.viewAllLink}
				/>
			)}
		</Fragment>
	);
};
