import React, { Fragment, type MouseEvent, useCallback, useState } from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import { getSessionItem } from '@atlassian/search-dialog';

import { useAIAnswerContext } from '../../../../ai-answer-context';
import { SEARCH_AI_ANSWER_CACHE_KEY } from '../../../../ai-answer-dialog';
import { onRecentWorkLinkClick, onRecentWorkLinkContextMenuClick } from '../../../../analytics';
import {
	type AIAnswerCacheType,
	type NLPSearchWorkResultRecentActivityDataType,
} from '../../../../types';

import { messages } from './messages';
import { RecentWorkItem } from './recent-work-item';
import {
	EmptyStateTextWrapper,
	LinkWrapper,
	RecentWorkListTitle,
	RecentWorkListWrapper,
} from './styled';

const COLLAPSED_RECENT_WORK_ITEM_NUMBER = 5;

type RecentWorkListProps = {
	name: string;
	viewAllUrl?: string;
	activities: NLPSearchWorkResultRecentActivityDataType[];
};

export const RecentWorkList = ({ name, activities, viewAllUrl }: RecentWorkListProps) => {
	const { formatMessage } = useIntl();
	const cachedExpanded = (
		JSON.parse(getSessionItem(SEARCH_AI_ANSWER_CACHE_KEY) || '{}') as AIAnswerCacheType
	).recentWorkListExpanded;
	const [expanded, setExpanded] = useState<boolean>(
		cachedExpanded !== undefined ? cachedExpanded : false,
	);
	const { updateContextCache, fireAnalyticsEvent, commonAttributes, onNavigate } =
		useAIAnswerContext();

	const expandRecentWorkList = useCallback(() => {
		setExpanded(true);
		updateContextCache({
			recentWorkListExpanded: true,
		});
	}, [updateContextCache]);

	const onLinkClick = useCallback(
		(event: MouseEvent) => {
			onNavigate(event);
			fireAnalyticsEvent(onRecentWorkLinkClick(commonAttributes));
		},
		[fireAnalyticsEvent, commonAttributes, onNavigate],
	);

	const onLinkContextMenuClick = useCallback(
		(event: MouseEvent) => {
			onNavigate(event);
			fireAnalyticsEvent(onRecentWorkLinkContextMenuClick(commonAttributes));
		},
		[fireAnalyticsEvent, commonAttributes, onNavigate],
	);

	const showExpandedItems = activities.length > COLLAPSED_RECENT_WORK_ITEM_NUMBER ? expanded : true;

	const shownActivities = showExpandedItems
		? activities
		: activities.slice(0, COLLAPSED_RECENT_WORK_ITEM_NUMBER);

	if (activities.length === 0) {
		return (
			<EmptyStateTextWrapper>
				{formatMessage(messages.no_activity_error_text, { user: name })}
			</EmptyStateTextWrapper>
		);
	}

	return (
		<Fragment>
			<RecentWorkListTitle>{formatMessage(messages.recentWorkListTitle)}</RecentWorkListTitle>
			<RecentWorkListWrapper>
				{shownActivities.map((activity, index) => (
					<RecentWorkItem
						key={`recent-work-item-${index}`}
						index={index}
						title={activity.title}
						iconUrl={activity.iconUrl}
						url={activity.url}
						container={activity.containerName}
						event={activity.event}
						contributors={activity.contributors}
						timestamp={activity.timestamp}
						othersCount={activity.othersCount}
					/>
				))}
			</RecentWorkListWrapper>
			{showExpandedItems ? (
				<LinkWrapper>
					<Button
						appearance={'link'}
						href={viewAllUrl}
						onClick={onLinkClick}
						onContextMenu={onLinkContextMenuClick}
					>
						{formatMessage(messages.viewAllLink)}
					</Button>
				</LinkWrapper>
			) : (
				<Fragment>
					{!!viewAllUrl && (
						<LinkWrapper>
							<Button appearance="link" onClick={expandRecentWorkList}>
								{formatMessage(messages.showMore)}
							</Button>
						</LinkWrapper>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};
