import { NLPSearchResultFormat, type NLPSearchType } from '../../../../ai-answer-dialog/types';
import {
	type ConfluenceScopeType,
	EditedDefinitionScope,
	type JiraScopeType,
} from '../../curated/types';
import { type CreateDefinitionDetailsType } from '../../gql/create-definition-mutation';

export const getReferenceQueryOrLinkTitle = (query: string) => {
	const linkRegex = /spaces\/.*\/(pages|blog)/;
	if (query.match(linkRegex)) {
		// e.g. https://hello.atlassian.net/wiki/spaces/AAI/pages/3665fakeId/Test+Page+Title
		const queryParts = query.split('/');
		return queryParts[queryParts.length - 1];
	}
	return query;
};

export const getEntityIdInScope = ({
	selectedScope,
	productScopes,
	cloudId,
}: {
	selectedScope: EditedDefinitionScope;
	productScopes: ConfluenceScopeType | JiraScopeType;
	cloudId: string;
}) => {
	let contentARI = '';
	let spaceARI = '';
	let projectARI = '';
	if ('contentARI' in productScopes) {
		contentARI = productScopes.contentARI;
		spaceARI = productScopes.spaceARI;
	}
	if ('projectARI' in productScopes) {
		projectARI = productScopes.projectARI;
	}

	switch (selectedScope) {
		case EditedDefinitionScope.PAGE:
		case EditedDefinitionScope.BLOGPOST:
			return contentARI;
		case EditedDefinitionScope.SPACE:
			return spaceARI;
		case EditedDefinitionScope.PROJECT:
			return projectARI;
		default:
			return cloudId;
	}
};

export const mapCreatedDefinitionToNLPSearchType = (
	definitionDetails: CreateDefinitionDetailsType,
): NLPSearchType => {
	const definitionReferenceDetails = definitionDetails.confluenceEntity;

	return {
		nlpResult: definitionDetails.definition,
		uniqueSources: definitionDetails.confluenceEntity
			? [
					{
						id: definitionReferenceDetails?.id || '',
						title: definitionReferenceDetails?.title || '',
						url: definitionDetails.referenceUrl || '',
						type: definitionReferenceDetails?.type || '',
						lastModified: '',
						iconUrl: '',
						spaceName: definitionReferenceDetails?.space?.name || '',
						spaceUrl: null,
					},
				]
			: [],
		nlpResultEditor: {
			name: definitionDetails.editor?.name || '',
			id: definitionDetails.editor?.accountId || '',
		},
		disclaimer: null,
		errorState: null,
		format: NLPSearchResultFormat.MARKDOWN,
	};
};
