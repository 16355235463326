import React, { type ReactNode, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { HorizontalScrollContainer } from '@atlassian/conversation-assistant-ui-components';
import { useActivityClient } from '@atlassian/recent-work-client';

import { useAIAnswerContext } from '../ai-answer-dialog/ai-answer-context';
import {
	NLPSearchErrorState,
	type NLPSearchPersonResultDataType,
	NLPSearchResultFormat,
	type NLPSearchWhoResultDataType,
} from '../ai-answer-dialog/types';
import { getTypedJSONResultData } from '../ai-answer-dialog/utils/getTypedJSONResultData';
import { useAnalytics } from '../common/controller/analytics';

import { onKnowledgeCardShown } from './analytics';
import { fetchPrimaryUserData } from './gql/hydrate-people-card';
import { KnowledgeCardsBody } from './knowledge-cards-body';
import { KnowledgeCardsHeader } from './knowledge-cards-header';
import { KnowledgeCardsTopics } from './knowledge-cards-topics';
import { PersonCard } from './person-card';
import { SeparatorLine } from './separator-line';
import { KnowledgeCardsIntentType } from './types';

const ScrollableContainerStyles = xcss({
	padding: 'space.100',
	margin: 'auto',
	marginBottom: 'space.100',
});

const CardContainerStyles = xcss({
	boxShadow: 'elevation.shadow.overflow',
	borderRadius: token('border.radius.100'),
});

const TopicsContainerStyles = xcss({
	marginTop: 'space.negative.200', // negative margins to pull topics card into place
	marginBottom: 'space.050',
});

type IndividualPersonKnowledgeCardProps = {
	primaryUser: NLPSearchWhoResultDataType | undefined;
};

const IndividualPersonKnowledgeCard = ({ primaryUser }: IndividualPersonKnowledgeCardProps) => {
	const { isAdminHubAIEnabled, commonAttributes } = useAIAnswerContext();
	const { cloudId, sessionId } = commonAttributes;
	const showAITopics = isAdminHubAIEnabled && fg('ai_topics_surface_person_knowledge_card');

	if (!primaryUser) {
		return null;
	}

	return (
		<Stack xcss={CardContainerStyles}>
			<KnowledgeCardsHeader
				type={KnowledgeCardsIntentType.PERSON}
				name={primaryUser.name}
				email={primaryUser.email}
				avatarUrl={primaryUser.avatar_url}
				profileUrl={primaryUser.profile_url}
				jobTitle={primaryUser.job_title}
				department={primaryUser.department}
				location={primaryUser.location}
				time={primaryUser.time}
			/>
			{showAITopics && (
				<Box xcss={TopicsContainerStyles}>
					<KnowledgeCardsTopics
						cloudId={cloudId}
						userId={primaryUser.account_id ?? ''}
						userName={primaryUser.name}
						sessionId={sessionId}
						fallbackSection={<></>}
						v2styles={true}
					/>
				</Box>
			)}
			<SeparatorLine />
			<KnowledgeCardsBody
				type={KnowledgeCardsIntentType.PERSON}
				currentUserId={primaryUser.account_id ?? ''}
				recommendedUsers={primaryUser.collaborators || []}
				recentActivity={primaryUser.recentActivity || []}
				profileUrl={primaryUser.profile_url || ''}
			/>
		</Stack>
	);
};

export const PersonKnowledgeCard = () => {
	const {
		commonAttributes,
		userDetails: { id: requestingUserId },
		setData,
	} = useAIAnswerContext();
	const { locale } = useIntl();
	const activityClient = useActivityClient('v3', 'people');
	const [fireAnalyticsEvent] = useAnalytics();
	const [selectedUser, setSelectedUser] = useState<number | undefined>();
	const [primaryUser, setPrimaryUser] = useState<NLPSearchWhoResultDataType | undefined>();
	const { answerString, cloudId } = commonAttributes;

	const userResults: NLPSearchPersonResultDataType[] = getTypedJSONResultData(answerString)
		.parsedResult as NLPSearchPersonResultDataType[];

	const ScrollWrapper =
		userResults.length > 3
			? (props: { children: ReactNode }) => (
					<HorizontalScrollContainer>
						<Box xcss={ScrollableContainerStyles}>{props.children}</Box>
					</HorizontalScrollContainer>
				)
			: React.Fragment;

	const getHandleSelectUser = useCallback(
		(index: number) => () => {
			setSelectedUser(index);
		},
		[],
	);

	useEffect(() => {
		if (userResults.length === 1) {
			setSelectedUser(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userResults]);

	useEffect(() => {
		const fetchData = async () => {
			if (selectedUser !== undefined) {
				return fetchPrimaryUserData({
					accountId: userResults[selectedUser].id,
					tenantId: cloudId,
					requestingUserId: requestingUserId || '',
					activityClient,
					locale,
				})
					.then((data) => {
						setPrimaryUser(data);
					})
					.catch((error) => {
						setData({
							errorState: NLPSearchErrorState.NetworkError,
							disclaimer: null,
							format: NLPSearchResultFormat.JSON,
							nlpResults: [],
							uniqueSources: [],
							nlpFollowUpResults: null,
						});
					});
			}
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser]);

	useEffect(() => {
		fireAnalyticsEvent(
			onKnowledgeCardShown({
				...commonAttributes,
				cardType: KnowledgeCardsIntentType.PERSON,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return selectedUser === undefined && userResults.length > 1 ? (
		// adds horizontal scroll when more than 3 users
		<ScrollWrapper>
			<Inline space="space.300">
				{userResults.map((user, index) => (
					<PersonCard
						name={user.name}
						profilePicUrl={user.avatarUrl ?? ''}
						jobTitle={user.title}
						isHoverState={false}
						onCardClick={getHandleSelectUser(index)}
					/>
				))}
			</Inline>
		</ScrollWrapper>
	) : (
		<IndividualPersonKnowledgeCard primaryUser={primaryUser} />
	);
};
