import React, { Fragment, useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { useAIAnswerContext } from '../../../ai-answer-dialog/ai-answer-context';
import { onDislikeButtonClick, onLikeButtonClick } from '../analytics';
import { ThumbsIcon } from '../common/icons/thumb-icon';

import { messages } from './messages';
import { FeedbackButtonIconWrapper, Rotate180IconWrapper } from './styled';

type FeedbackButtonProps = {
	active: boolean;
	onClick: (e: any) => void;
	tooltipContent: string;
	icon: JSX.Element;
	isDisabled?: boolean;
	testId?: string;
};

const FeedbackButton = ({
	active,
	isDisabled,
	onClick: onClickHandler,
	tooltipContent,
	testId,
	icon,
}: FeedbackButtonProps) => {
	return (
		<Tooltip position={'top'} content={tooltipContent}>
			{({ onClick, ...tooltipProps }) => (
				<Button
					aria-label={tooltipContent}
					spacing={'none'}
					appearance={isDisabled ? 'subtle-link' : 'subtle'}
					iconBefore={
						<FeedbackButtonIconWrapper
							active={active}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={{ cursor: 'pointer' }}
							{...(testId && { 'data-testid': testId })}
							isDisabled={isDisabled || false}
						>
							{icon}
						</FeedbackButtonIconWrapper>
					}
					{...(!isDisabled && { onClick: onClickHandler })}
					{...tooltipProps}
				/>
			)}
		</Tooltip>
	);
};

type FeedbackButtonsProps = {
	setShowFeedbackOptionsModal: (value: boolean) => void;
	setDisplayFlag: (value: boolean) => void;
};

export const FeedbackButtons = ({
	setShowFeedbackOptionsModal,
	setDisplayFlag,
}: FeedbackButtonsProps) => {
	const { formatMessage } = useIntl();
	const {
		commonAttributes,
		fireAnalyticsEvent,
		updateContextCache,
		liked,
		setLiked,
		likesDisabled,
		setLikesDisabled,
	} = useAIAnswerContext();
	const { trackAIFeedbackSubmit } = useAIEventsInstrumentation();
	// Using a single state to represent both like/dislike states
	// true - liked, false - disliked, undefined - neither are selected
	const likeButtonCallback = useCallback(
		(e: any) => {
			e.preventDefault();
			setDisplayFlag(true);
			setLiked(true);
			setLikesDisabled(true);
			updateContextCache({ collapsed: false, liked: true });
			fireAnalyticsEvent(onLikeButtonClick(commonAttributes));
			trackAIFeedbackSubmit('up');
		},
		[
			setLiked,
			setLikesDisabled,
			fireAnalyticsEvent,
			commonAttributes,
			updateContextCache,
			setDisplayFlag,
			trackAIFeedbackSubmit,
		],
	);

	const dislikeButtonCallback = useCallback(
		(e: any) => {
			e.preventDefault();
			setShowFeedbackOptionsModal(true);
			setLiked(false);
			setLikesDisabled(true);
			updateContextCache({ collapsed: false, liked: false });
			fireAnalyticsEvent(onDislikeButtonClick(commonAttributes));
			trackAIFeedbackSubmit('down');
		},
		[
			setLiked,
			setLikesDisabled,
			fireAnalyticsEvent,
			commonAttributes,
			updateContextCache,
			setShowFeedbackOptionsModal,
			trackAIFeedbackSubmit,
		],
	);

	return (
		<Fragment>
			<FeedbackButton
				active={liked === true}
				onClick={likeButtonCallback}
				tooltipContent={formatMessage(messages.definition_footer_like_button_tooltip)}
				icon={<ThumbsIcon />}
				isDisabled={liked && likesDisabled}
				testId="definition-footer-like-button"
			/>
			<FeedbackButton
				active={liked === false}
				onClick={dislikeButtonCallback}
				tooltipContent={formatMessage(messages.definition_footer_dislike_button_tooltip)}
				icon={
					<Rotate180IconWrapper>
						<ThumbsIcon />
					</Rotate180IconWrapper>
				}
				isDisabled={!liked && likesDisabled}
				testId="definition-footer-dislike-button"
			/>
		</Fragment>
	);
};
