import React from 'react';

import { TeamKnowledgeCard } from '../../../../knowledge-cards/team-knowledge-card';
import { useAIAnswerContext } from '../../../ai-answer-context';
import { NLPSearchJSONResultFormat } from '../../../types';
import { getTypedJSONResultDataAssistanceService } from '../../../utils/getTypedJSONResultData';

import { AIAnswerWhoResult } from './who-result';
import { AIAnswerWorkResult } from './work-result';

export const JsonResultComponent = () => {
	const { commonAttributes } = useAIAnswerContext();

	const { answerString } = commonAttributes;
	const { parsedType } = getTypedJSONResultDataAssistanceService(answerString);
	switch (parsedType) {
		case NLPSearchJSONResultFormat.WHO:
			return <AIAnswerWhoResult />;
		case NLPSearchJSONResultFormat.WORK:
			return <AIAnswerWorkResult />;
		case NLPSearchJSONResultFormat.TEAM:
			return <TeamKnowledgeCard />;
		default:
			return null;
	}
};
