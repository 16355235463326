import React, { useEffect, useRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	position: 'relative',
	width: '20px',
	height: '20px',
});

// We need to scale the canvas down to increase the sharpness of the circle
// Otherwise the graphics will be blurry
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CanvasWrapper = styled.div({
	position: 'absolute',
	width: '200px',
	height: '200px',
	top: '-90px',
	left: '-90px',
	transform: 'scale(0.1)',
});

type RadialProgressBarProps = {
	current: number;
	max: number;
};

export const RadialProgressBar = ({ current, max }: RadialProgressBarProps) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	// Draw the current circle progress bar
	useEffect(() => {
		const ctx = canvasRef.current?.getContext('2d');
		if (ctx) {
			if (current >= max) {
				ctx.beginPath();
				ctx.strokeStyle = '#E91618';
				ctx.lineWidth = 20;
				ctx.arc(100, 100, 80, 0, 2 * Math.PI);
				ctx.stroke();
			} else {
				ctx.beginPath();
				ctx.strokeStyle = '#DCDFE4';
				ctx.lineWidth = 20;
				ctx.arc(100, 100, 80, 0, 2 * Math.PI);
				ctx.stroke();
				ctx.beginPath();
				ctx.strokeStyle = '#22A06B';
				ctx.lineWidth = 20;
				ctx.arc(100, 100, 80, -0.5 * Math.PI, 2 * Math.PI * (current / max) - 0.5 * Math.PI);
				ctx.stroke();
			}
		}
	}, [current, max]);

	return (
		<Wrapper>
			<CanvasWrapper>
				<canvas ref={canvasRef} height={200} width={200} />
			</CanvasWrapper>
		</Wrapper>
	);
};
