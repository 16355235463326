import { type EditedDefinitionScope } from '../../curated/types';
import type { DefinitionConfluenceScope, DefinitionJiraScope } from '../types';

type EditedDefinitionType = {
	keyPhrase: string;
	definition: string;
	scope: EditedDefinitionScope;
	editor?: {
		name: string;
		accountId: string;
		picture: string;
	};
	referenceUrl?: string | null;
	confluenceEntity?: {
		id: string;
		type: string | null;
		title: string | null;
	} | null;
	createdAt: string;
};

interface DefinitionEditHistoryType {
	definitions?: EditedDefinitionType[] | null;
	message?: string | null;
}

interface DefinitionEditHistoryQueryType {
	knowledgeDiscovery: {
		definitionHistory: DefinitionEditHistoryType;
	};
}

interface DefinitionEditHistoryQueryVariables {
	workspaceId: string;
	keyPhrase: string;
	confluenceScopeId?: DefinitionConfluenceScope;
	jiraScopeId?: DefinitionJiraScope;
}

const DefinitionEditHistoryQuery = `
query DefinitionEditHistoryQuery(
  $workspaceId: String!
  $keyPhrase: String!
	$confluenceScopeId: KnowledgeDiscoveryDefinitionScopeIdConfluence
	$jiraScopeId: KnowledgeDiscoveryDefinitionScopeIdJira
) {
  knowledgeDiscovery {
    definitionHistory(
      workspaceId: $workspaceId
      keyPhrase: $keyPhrase
      confluenceScopeId: $confluenceScopeId
			jiraScopeId: $jiraScopeId
    ) @optIn(to: "KnowledgeDiscovery Get definition history") {
      __typename
      ... on KnowledgeDiscoveryDefinitionList {
        definitions {
          keyPhrase
          definition
          createdAt
          scope
          editor {
            name
            accountId
            picture
          }
          referenceUrl
          confluenceEntity {
            ... on ConfluencePage {
              id
              type
              title
            }
            ... on ConfluenceBlogPost {
              id
              type
              title
            }
          }
        }
      }
      ... on QueryError {
        message
      }
    }
  }
}
`;

export const DefinitionEditHistoryGQL = {
	operationName: 'DefinitionEditHistoryQuery',
	query: DefinitionEditHistoryQuery,
};

export type { DefinitionEditHistoryQueryType, DefinitionEditHistoryQueryVariables };
