// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { N0, N40, N40A, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// styling for primary user section results e.g. frequent collaborators
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerSectionWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	marginTop: token('space.200', '16px'),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerSectionHeader = styled.h5({
	fontWeight: 600,
	fontSize: '12px',
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerSectionResultsWrapper = styled.div({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
	gap: token('space.100', '8px'),
	width: '100%',
	height: '56px',
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerSectionResult = styled.a({
	display: 'flex',
	alignItems: 'center',
	padding: token('space.150', '12px'),
	border: `solid ${token('color.border', N40)} 1px`,
	borderRadius: '6px',
	boxSizing: 'border-box',
	'&:hover': {
		textDecoration: 'none',
		boxShadow: token(
			'elevation.shadow.overlay',
			'0px 0px 8px 0px rgba(9, 30, 66, 0.16), 0px 0px 1px 0px rgba(9, 30, 66, 0.12)',
		),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerSectionResultInfoWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-evenly',
	alignItems: 'flex-start',
	height: '100%',
	width: '100%',
	marginLeft: token('space.100', '8px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerSectionResultInfoHeader = styled.div({
	fontWeight: 590,
	fontSize: '14px',
	lineHeight: '16px',
	color: token('color.text', N800),
	width: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerSectionResultMetadata = styled.div({
	fontWeight: 400,
	fontSize: '11px',
	lineHeight: '14px',
	color: token('color.text.subtlest', '#626F86'),
	width: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// styling for additional matches presented in footer of dialog
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerFooter = styled.div({
	padding: token('space.300', '24px'),
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	borderTop: `1px solid ${token('color.border', N40A)}`,
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerFooterHeader = styled.div({
	fontWeight: 600,
	fontSize: '12px',
	lineHeight: '16px',
	color: token('color.text.subtlest', '#626F86'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerFooterResultsWrapper = styled.div({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
	gap: token('space.100', '8px'),
	width: '100%',
	height: '56px',
	marginTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerFooterResult = styled.a({
	display: 'flex',
	alignItems: 'center',
	padding: token('space.150', '12px'),
	border: `solid ${token('color.border', N40)} 1px`,
	borderRadius: '6px',
	boxSizing: 'border-box',
	background: token('color.background.input', N0),
	'&:hover': {
		textDecoration: 'none',
		boxShadow: token(
			'elevation.shadow.overlay',
			'0px 0px 8px 0px rgba(9, 30, 66, 0.16), 0px 0px 1px 0px rgba(9, 30, 66, 0.12)',
		),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerFooterResultInfoWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-evenly',
	alignItems: 'flex-start',
	height: '100%',
	width: '100%',
	marginLeft: token('space.100', '8px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerFooterResultInfoHeader = styled.div({
	fontWeight: 590,
	fontSize: '14px',
	lineHeight: '16px',
	color: token('color.text', N800),
	width: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIWhoAnswerFooterResultMetadata = styled.div({
	fontWeight: 400,
	fontSize: '11px',
	lineHeight: '14px',
	color: token('color.text.subtlest', '#626F86'),
	width: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
