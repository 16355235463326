import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import type { commonAttributesType } from './types';

export const getStreamFetchErrorEventPayload = (
	error: unknown,
	source: string,
): AnalyticsEventPayload => {
	if (error instanceof Error) {
		return {
			action: 'failed',
			actionSubject: 'aiAnswerDialogStreamFetch',
			source,
			eventType: 'track',
			attributes: {
				errorName: error.name,
				errorMessage: error.message,
				errorStack: error.stack?.split('\n').at(1) || '',
			},
		};
	}
	if (error instanceof Object && 'error' in error) {
		return {
			action: 'failed',
			actionSubject: 'aiAnswerDialogStreamFetch',
			source,
			eventType: 'track',
			attributes: {
				errorName: error.error instanceof Error ? error.error.name : '',
				errorMessage:
					error.error instanceof Error
						? error.error.message
						: typeof error === 'string'
							? error
							: '',
				errorStack: error.error instanceof Error ? error.error.stack?.split('\n').at(1) : '',
				reason: 'reason' in error ? error.reason : '',
				details: 'details' in error ? error.details : '',
			},
		};
	}

	return {
		action: 'failed',
		actionSubject: 'aiAnswerDialogStreamFetch',
		source,
		eventType: 'track',
		attributes: {
			reason: error instanceof Object && 'reason' in error ? error.reason : '',
			details: error instanceof Object && 'details' in error ? error.details : '',
		},
	};
};

export const getNonStreamFetchErrorEventPayload = (
	error: unknown,
	source: string,
): AnalyticsEventPayload => ({
	action: 'failed',
	actionSubject: 'aiAnswerDialogNonStreamFetch',
	source,
	eventType: 'track',
	attributes: {
		errorName: error instanceof Object && 'name' in error ? error.name : '',
		errorMessage: error instanceof Object && 'message' in error ? error.message : '',
		errorStack:
			error instanceof Object && 'stack' in error && typeof error.stack === 'string'
				? error.stack?.split('\n').at(1)
				: '',
	},
});

export const updateDefinitionErrorPayload = (
	error: unknown,
	commonAttributes: commonAttributesType,
): AnalyticsEventPayload => {
	const { extraAttributes, source } = commonAttributes;

	return {
		action: 'failed',
		actionSubject: 'aiCuratedDefinitionUpdate',
		source,
		eventType: 'track',
		attributes: {
			...extraAttributes,
			errorName: error instanceof Object && 'name' in error ? error.name : '',
			errorMessage: error instanceof Object && 'message' in error ? error.message : '',
			errorStack:
				error instanceof Object && 'stack' in error && typeof error.stack === 'string'
					? error.stack?.split('\n').at(1)
					: '',
		},
	};
};
