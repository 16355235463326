import React, { Fragment, lazy, Suspense, useCallback, useState } from 'react';

import { useIntl } from 'react-intl-next';

import Checkbox from '@atlaskit/checkbox';
import { Box, xcss } from '@atlaskit/primitives';

import { useAIAnswerContext } from '../../../ai-answer-dialog/ai-answer-context';
import { FEEDBACK_CONTEXT_CF, FEEDBACK_ENTRYPOINT_ID_DEFINITIONS } from '../common/constants';

import { messages } from './messages';

const AkFeedbackCollector = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/feedback-collector" */ '@atlaskit/feedback-collector'
	).then((module) => {
		return {
			default: module.default,
		};
	}),
);

const ReportDescriptionWrapperStyles = xcss({
	paddingBottom: 'space.200',
});

type FeedbackModalProps = {
	setFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
	isCuratedDefinition: boolean;
	setDisplayFlag: (value: boolean) => void;
};

export const FeedbackModal = ({
	setFeedbackModal,
	isCuratedDefinition,
	setDisplayFlag,
}: FeedbackModalProps) => {
	const { formatMessage, locale } = useIntl();
	const {
		commonAttributes,
		readingAidsOptions,
		userDetails: { name, id },
	} = useAIAnswerContext();
	const { errorState } = commonAttributes;

	const [hasConsent, setConsent] = useState(false);
	const feedbackContextObject = {
		source: commonAttributes.source,
		queryHash: commonAttributes.queryHash,
		cloudId: commonAttributes.cloudId,
		prompt: hasConsent ? commonAttributes.query : undefined,
		answer: hasConsent ? commonAttributes.answerString : undefined,
		documentId: readingAidsOptions?.contentARI,
		errorState: errorState,
		isCuratedDefinition: isCuratedDefinition,
	};

	const feedbackContext = Object.entries(feedbackContextObject)
		.map(([key, value]) => `${key}: ${value}`)
		.join(', ');

	const toggleConsentCheckbox = useCallback(() => {
		setConsent(!hasConsent);
	}, [hasConsent]);

	return (
		<Fragment>
			<Suspense fallback={<></>}>
				<AkFeedbackCollector
					locale={locale}
					onClose={() => setFeedbackModal(false)}
					onSubmit={() => {
						setDisplayFlag(true);
					}}
					customContent={
						isCuratedDefinition ? undefined : (
							<Checkbox
								label={formatMessage(messages.definition_footer_feedback_consent_checkbox_label)}
								onChange={toggleConsentCheckbox}
								isChecked={hasConsent}
							/>
						)
					}
					atlassianAccountId={id}
					name={name}
					entrypointId={FEEDBACK_ENTRYPOINT_ID_DEFINITIONS}
					additionalFields={[
						{
							id: FEEDBACK_CONTEXT_CF,
							value: feedbackContext,
						},
					]}
					feedbackTitle={formatMessage(messages.definition_footer_feedback_modal_header)}
					feedbackTitleDetails={
						<Box xcss={ReportDescriptionWrapperStyles}>
							{formatMessage(messages.definition_footer_feedback_report_label)}
						</Box>
					}
					feedbackGroupLabels={{
						empty: {
							fieldLabel: formatMessage(messages.definition_footer_feedback_report_details_label),
							selectOptionLabel: formatMessage(
								messages.definition_footer_feedback_report_empty_option_share_label,
							),
						},
						question: {
							fieldLabel: formatMessage(messages.definition_footer_feedback_report_details_label),
							selectOptionLabel: formatMessage(
								messages.definition_footer_feedback_report_relevance_option_label,
							),
						},
						comment: {
							fieldLabel: formatMessage(messages.definition_footer_feedback_report_details_label),
							selectOptionLabel: formatMessage(
								messages.definition_footer_feedback_report_inaccurate_option_label,
							),
						},
						bug: {
							fieldLabel: formatMessage(messages.definition_footer_feedback_report_details_label),
							selectOptionLabel: formatMessage(
								messages.definition_footer_feedback_report_offensive_option_label,
							),
						},
						suggestion: {
							fieldLabel: formatMessage(messages.definition_footer_feedback_report_details_label),
							selectOptionLabel: formatMessage(
								messages.definition_footer_feedback_report_other_option_label,
							),
						},
					}}
					summaryPlaceholder={formatMessage(
						messages.definition_footer_feedback_summary_placeholder_label,
					)}
				/>
			</Suspense>
		</Fragment>
	);
};
