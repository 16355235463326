import React, { useCallback, useState } from 'react';

import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { N700 } from '@atlaskit/theme/colors';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { useAIAnswerContext } from '../ai-answer-context';
import { type ReadingAidsMenuOptionControlTypes } from '../types';

import {
	AIOptionsMenuOptionDescription,
	AIOptionsMenuOptionHeader,
	AIOptionsMenuOptionText,
	AIOptionsMenuOptionToggleWrapper,
	AIOptionsMenuOptionWrapper,
	AIOptionsMenuWrapper,
} from './styled';

type RenderOptionsMenuControlProps = {
	onChange: () => void;
	type: ReadingAidsMenuOptionControlTypes;
	isChecked?: boolean;
};

const messages = defineMessages({
	definition_more_icon_tooltip: {
		id: 'search_ai.definition_more_icon_tooltip',
		defaultMessage: 'See more actions',
		description: 'Tooltip for definition more icon',
	},
});

const renderOptionsMenuControl = ({ onChange, type, isChecked }: RenderOptionsMenuControlProps) => {
	switch (type) {
		case 'toggle':
			return (
				<Toggle onChange={onChange} isChecked={isChecked} testId="ai-answer-options-menu-toggle" />
			);
		default:
			return <></>;
	}
};

const MoreIconStyled = () => (
	<MoreIcon label="reading-aids-menu-button-icon" color={token('color.icon.subtle', N700)} />
);

type AIOptionsMenuOptionProps = {
	header: string;
	onChange: () => void;
	type: ReadingAidsMenuOptionControlTypes;
	description?: string;
	isChecked?: boolean;
};

const AIOptionsMenuOption = ({
	header,
	description,
	onChange,
	type,
	isChecked,
}: AIOptionsMenuOptionProps) => {
	return (
		<AIOptionsMenuOptionWrapper>
			<AIOptionsMenuOptionText>
				<AIOptionsMenuOptionHeader>{header}</AIOptionsMenuOptionHeader>
				<AIOptionsMenuOptionDescription>{description}</AIOptionsMenuOptionDescription>
			</AIOptionsMenuOptionText>
			<AIOptionsMenuOptionToggleWrapper>
				{renderOptionsMenuControl({
					onChange,
					type,
					isChecked,
				})}
			</AIOptionsMenuOptionToggleWrapper>
		</AIOptionsMenuOptionWrapper>
	);
};

export const ReadingAidsMenuButton = () => {
	const { formatMessage } = useIntl();
	const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
	const { readingAidsOptions } = useAIAnswerContext();
	const { menuOptions } = readingAidsOptions || {};

	const toggleMenuOpen = useCallback(() => {
		setMenuOpen(!isMenuOpen);
	}, [isMenuOpen]);

	return (
		<Tooltip position="bottom" content={formatMessage(messages.definition_more_icon_tooltip)}>
			<DropdownMenu
				isOpen={isMenuOpen}
				trigger={({ triggerRef, ...props }) => (
					<IconButton
						{...props}
						appearance="subtle"
						icon={MoreIconStyled}
						ref={triggerRef}
						onClick={toggleMenuOpen}
						testId="reading-aids-menu-button"
						label="reading-aids-menu-button"
					/>
				)}
			>
				<AIOptionsMenuWrapper onClick={(e) => e.stopPropagation()}>
					{menuOptions?.length &&
						menuOptions.map((menuOption, index) => {
							return (
								<AIOptionsMenuOption
									header={formatMessage(menuOption.label)}
									description={menuOption.description && formatMessage(menuOption.description)}
									onChange={menuOption.onChange}
									isChecked={menuOption.toggleState}
									type={menuOption.type}
									key={index}
								/>
							);
						})}
				</AIOptionsMenuWrapper>
			</DropdownMenu>
		</Tooltip>
	);
};
