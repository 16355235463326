import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	scrollLeft: {
		id: 'horizontal-scroll-container.scroll-left',
		defaultMessage: 'Scroll left',
		description: 'Label for the button that move the scroll to the left',
	},
	scrollRight: {
		id: 'horizontal-scroll-container.scroll-right',
		defaultMessage: 'Scroll right',
		description: 'Label for the button that move the scroll to the right',
	},
});
