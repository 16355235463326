import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import EditIcon from '@atlaskit/icon/core/edit';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { EditDefinitionModal } from '../../v2/definitions/curated/edit-definition/edit-definition-modal';
import { useAIAnswerContext } from '../ai-answer-context';
import {
	onCuratedDefinitionEditButtonClicked,
	onCuratedDefinitionEditButtonShown,
} from '../analytics';

import { ReadingAidsMenuButton } from './reading-aids-menu-button';
import { ReadingAidsOptionsWrapper } from './styled';

const EditIconStyled = () => <EditIcon color={token('color.icon.subtle', N700)} label="" />;

const messages = defineMessages({
	edit_definition_icon_tooltip: {
		id: 'search_ai.curated-definition-edit-def-icon-tooltip',
		defaultMessage: 'Edit definition',
		description: 'Tooltip for edit definition icon',
	},
	definition_more_icon_tooltip: {
		id: 'search_ai.definition_more_icon_tooltip',
		defaultMessage: 'See more actions',
		description: 'Tooltip for definition more icon',
	},
});

export const ReadingAidsOptions = () => {
	const { fireAnalyticsEvent, isAnswered, hasError, readingAidsOptions, commonAttributes } =
		useAIAnswerContext();
	const { formatMessage } = useIntl();
	const [showEditModal, setShowEditModal] = useState(false);
	const openEditModal = useCallback(() => {
		fireAnalyticsEvent(onCuratedDefinitionEditButtonClicked(commonAttributes));
		setShowEditModal(true);
	}, [commonAttributes, fireAnalyticsEvent]);
	const closeEditModal = useCallback(() => setShowEditModal(false), []);

	const { definitionCurationOptions, menuOptions } = readingAidsOptions || {};

	const showMenuButton = !!menuOptions?.length;
	const editDefinitionEnabled = !!definitionCurationOptions;

	const editButtonSpotlightId = 'ai-definitions-edit-button';

	useEffect(() => {
		if (definitionCurationOptions && definitionCurationOptions.editModalShownRef) {
			definitionCurationOptions.editModalShownRef.current = showEditModal;
		}
	}, [definitionCurationOptions, showEditModal]);

	useEffect(() => {
		if (isAnswered && editDefinitionEnabled) {
			fireAnalyticsEvent(onCuratedDefinitionEditButtonShown(commonAttributes));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAnswered, editDefinitionEnabled]);

	if (!showMenuButton && !editDefinitionEnabled) {
		return null;
	}

	return (
		<Fragment>
			<ReadingAidsOptionsWrapper>
				{!hasError && editDefinitionEnabled && (
					<SpotlightManager>
						<SpotlightTarget name={editButtonSpotlightId}>
							<Tooltip
								position="bottom"
								content={formatMessage(messages.edit_definition_icon_tooltip)}
							>
								<IconButton
									appearance="subtle"
									onClick={openEditModal}
									label="edit-definition-button"
									icon={EditIconStyled}
								/>
							</Tooltip>
						</SpotlightTarget>
						<EngagementSpotlight engagementId={editButtonSpotlightId} />
					</SpotlightManager>
				)}
				{showMenuButton && <ReadingAidsMenuButton />}
			</ReadingAidsOptionsWrapper>
			<ModalTransition>
				{editDefinitionEnabled && showEditModal && (
					<EditDefinitionModal
						curatedDefinitionScopeConfig={definitionCurationOptions.curatedDefinitionScopeConfig}
						closeEditModal={closeEditModal}
					/>
				)}
			</ModalTransition>
		</Fragment>
	);
};
