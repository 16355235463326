import { defineMessages } from 'react-intl-next';

const youSingularWorkedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.worked.today',
		defaultMessage: 'You worked on this today',
		description:
			'SAIN recent work list message indicating that the current user worked on something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.worked.yesterday',
		defaultMessage: 'You worked on this yesterday',
		description:
			'SAIN recent work list message indicating that the current user worked on something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.worked.otherTime',
		defaultMessage: 'You worked on this {time}',
		description:
			'SAIN recent work list message indicating that the current user worked on something on some date',
	},
});

const youSingularCreatedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.created.today',
		defaultMessage: 'You created this today',
		description:
			'SAIN recent work list message indicating that the current user created something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.created.yesterday',
		defaultMessage: 'You created this yesterday',
		description:
			'SAIN recent work list message indicating that the current user created something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.created.otherTime',
		defaultMessage: 'You created this on {time}',
		description:
			'SAIN recent work list message indicating that the current user created something on some date',
	},
});

const youSingularEditedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.edited.today',
		defaultMessage: 'You edited this today',
		description:
			'SAIN recent work list message indicating that the current user edited something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.edited.yesterday',
		defaultMessage: 'You edited this yesterday',
		description:
			'SAIN recent work list message indicating that the current user edited something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.edited.otherTime',
		defaultMessage: 'You edited this on {time}',
		description:
			'SAIN recent work list message indicating that the current user edited something on some date',
	},
});

const youSingularCommentedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.commented.today',
		defaultMessage: 'You commented here today',
		description:
			'SAIN recent work list message indicating that the current user commented on something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.commented.yesterday',
		defaultMessage: 'You commented here yesterday',
		description:
			'SAIN recent work list message indicating that the current user commented on something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.commented.otherTime',
		defaultMessage: 'You commented here on {time}',
		description:
			'SAIN recent work list message indicating that the current user commented on something on some date',
	},
});

const youSingularUpdatedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.updated.today',
		defaultMessage: 'You updated this today',
		description:
			'SAIN recent work list message indicating that the current user updated something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.updated.yesterday',
		defaultMessage: 'You updated this yesterday',
		description:
			'SAIN recent work list message indicating that the current user updated something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_singular.updated.otherTime',
		defaultMessage: 'You updated this on {time}',
		description:
			'SAIN recent work list message indicating that the current user updated something on some date',
	},
});

const otherSingularWorkedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.worked.today',
		defaultMessage: '{name} worked on this today',
		description:
			'SAIN recent work list message indicating that some user worked on something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.worked.yesterday',
		defaultMessage: '{name} worked on this yesterday',
		description:
			'SAIN recent work list message indicating that some user worked on something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.worked.otherTime',
		defaultMessage: '{name} worked on this on {time}',
		description:
			'SAIN recent work list message indicating that some user worked on something on some date',
	},
});

const otherSingularCreatedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.created.today',
		defaultMessage: '{name} created this today',
		description: 'SAIN recent work list message indicating that some user created something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.created.yesterday',
		defaultMessage: '{name} created this yesterday',
		description:
			'SAIN recent work list message indicating that some user created something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.created.otherTime',
		defaultMessage: '{name} created this on {time}',
		description:
			'SAIN recent work list message indicating that some user created something on some date',
	},
});

const otherSingularEditedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.edited.today',
		defaultMessage: '{name} edited this today',
		description: 'SAIN recent work list message indicating that some user edited something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.edited.yesterday',
		defaultMessage: '{name} edited this yesterday',
		description:
			'SAIN recent work list message indicating that some user edited something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.edited.otherTime',
		defaultMessage: '{name} edited this on {time}',
		description:
			'SAIN recent work list message indicating that some user edited something on some date',
	},
});

const otherSingularCommentedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.commented.today',
		defaultMessage: '{name} commented here today',
		description:
			'SAIN recent work list message indicating that some user commented on something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.commented.yesterday',
		defaultMessage: '{name} commented here yesterday',
		description:
			'SAIN recent work list message indicating that some user commented on something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.commented.otherTime',
		defaultMessage: '{name} commented here on {time}',
		description:
			'SAIN recent work list message indicating that some user commented on something on some date',
	},
});

const otherSingularUpdatedMessages = defineMessages({
	today: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.updated.today',
		defaultMessage: '{name} updated this today',
		description: 'SAIN recent work list message indicating that some user updated something today',
	},
	yesterday: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.updated.yesterday',
		defaultMessage: '{name} updated this yesterday',
		description:
			'SAIN recent work list message indicating that some user updated something yesterday',
	},
	otherTime: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_singular.updated.otherTime',
		defaultMessage: '{name} updated this on {time}',
		description:
			'SAIN recent work list message indicating that some user updated something on some date',
	},
});

const youPluralActivityMessages = defineMessages({
	double: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_plural.double',
		defaultMessage: 'You and {name1} have both worked on this',
		description:
			'SAIN recent work list message indicating that the current user and 1 other worked on something',
	},
	triple: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_plural.triple',
		defaultMessage: 'You, {name1}, and {name2} have all worked on this',
		description:
			'SAIN recent work list message indicating that the current user and 2 others worked on something',
	},
	many: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.you_plural.many',
		defaultMessage: 'You, {name1}, and {count} others have all worked on this',
		description:
			'SAIN recent work list message indicating that the current user and many others worked on something',
	},
});

const otherPluralActivityMessages = defineMessages({
	double: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_plural.double',
		defaultMessage: '{name1} and {name2} have both worked on this',
		description: 'SAIN recent work list message indicating that 2 others worked on something',
	},
	triple: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_plural.triple',
		defaultMessage: '{name1}, {name2}, and {name3} have all worked on this',
		description: 'SAIN recent work list message indicating that 3 others worked on something',
	},
	many: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.other_plural.many',
		defaultMessage: '{name1}, {name2}, and {count} others have all worked on this',
		description: 'SAIN recent work list message indicating that many others worked on something',
	},
});

export const youSingularActivityMessages = {
	worked: youSingularWorkedMessages,
	created: youSingularCreatedMessages,
	edited: youSingularEditedMessages,
	commented: youSingularCommentedMessages,
	updated: youSingularUpdatedMessages,
};

export const otherSingularActivityMessages = {
	worked: otherSingularWorkedMessages,
	created: otherSingularCreatedMessages,
	edited: otherSingularEditedMessages,
	commented: otherSingularCommentedMessages,
	updated: otherSingularUpdatedMessages,
};

export const pluralActivityMessages = {
	you: youPluralActivityMessages,
	other: otherPluralActivityMessages,
};
