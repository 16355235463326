import React, { Fragment, Suspense, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { messages } from '../../messages';
import { AIDefinitionFooterSection } from '../../v2/definitions/footer/footer-section';
import { useAIAnswerContext } from '../ai-answer-context';
import { SmartAnswerFooterSection } from '../ai-answer-footer';
import { onAIAnswerLoadingDialogShown } from '../analytics';
import { NLPSearchResultFormat } from '../types';

import { AIAnswerLoadingStream } from './ai-answer-loading-stream';
import GeneratingAnimation from './generating-animation';
import { AIAnswerSearchLoadingBorder, GeneratingContainer, GeneratingText } from './styled';

const ReadingAidsLoadingDialog = () => {
	const { formatMessage } = useIntl();
	return (
		<Fragment>
			<GeneratingContainer>
				<GeneratingAnimation alt={formatMessage(messages.ai_answer_generating_icon_alt_text)} />
				<GeneratingText>
					<FormattedMessage {...messages.ai_answer_component_title_text_loading_reading_aids} />
				</GeneratingText>
			</GeneratingContainer>
			<AIDefinitionFooterSection />
		</Fragment>
	);
};

export const AIAnswerLoadingDialog = () => {
	const { commonAttributes, fireAnalyticsEvent, isReadingAids } = useAIAnswerContext();

	useEffect(() => {
		fireAnalyticsEvent(onAIAnswerLoadingDialogShown(commonAttributes));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isReadingAids ? <ReadingAidsLoadingDialog /> : <SmartAnswerLoadingDialog />;
};

const SmartAnswerLoadingDialog = () => {
	const { partialResponse } = useAIAnswerContext();
	const { formatMessage } = useIntl();

	if (!partialResponse || partialResponse?.format !== NLPSearchResultFormat.MARKDOWN) {
		return (
			<AIAnswerSearchLoadingBorder isStreaming={false}>
				<GeneratingContainer>
					<GeneratingAnimation alt={formatMessage(messages.ai_answer_generating_icon_alt_text)} />
					<GeneratingText>
						<FormattedMessage {...messages.ai_answer_generating_text} />
					</GeneratingText>
				</GeneratingContainer>
				<SmartAnswerFooterSection />
			</AIAnswerSearchLoadingBorder>
		);
	}

	return (
		<AIAnswerSearchLoadingBorder isStreaming>
			<Suspense fallback={<Fragment />}>
				<AIAnswerLoadingStream markdown={partialResponse.partialNlpResult} />
			</Suspense>
		</AIAnswerSearchLoadingBorder>
	);
};
