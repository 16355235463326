import React from 'react';

import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type TriggerProps } from '@atlaskit/tooltip';

import { KnowledgeDefaultSubText, KnowledgeDefaultText, PersonStyles, WorkStyles } from './styled';
import { KnowledgeCardsRowType } from './types';

type KnowledgeCardsRowProps = {
	primaryText: string;
	secondaryText: string;
	pictureUrl: string;
	type: KnowledgeCardsRowType;
	triggerProps?: TriggerProps;
	onClick?: () => void;
};

const rowBox = xcss({
	marginInlineEnd: 'space.100',
	borderRadius: '3px',
	':hover': {
		background: token('elevation.surface.hovered'),
		cursor: 'pointer',
	},
});

export const KnowledgeCardsRow = ({
	primaryText,
	secondaryText,
	pictureUrl,
	type,
	triggerProps,
	onClick,
}: KnowledgeCardsRowProps) => {
	return (
		<Box
			xcss={rowBox}
			paddingInline="space.200"
			paddingBlock="space.075"
			{...triggerProps}
			onClick={onClick}
		>
			<Inline space="space.100" alignBlock="center">
				{type === KnowledgeCardsRowType.PERSON ? (
					<PersonStyles src={pictureUrl} />
				) : (
					<WorkStyles src={pictureUrl} />
				)}
				<Stack spread="space-between">
					<KnowledgeDefaultText>{primaryText}</KnowledgeDefaultText>
					<KnowledgeDefaultSubText>{secondaryText}</KnowledgeDefaultSubText>
				</Stack>
			</Inline>
		</Box>
	);
};
