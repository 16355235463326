import { useCallback } from 'react';

import { useAIAnswerContext } from '../../../../ai-answer-dialog/ai-answer-context';
import { fetchAggResponse } from '../../../../common';
import {
	SearchSourceQuery,
	type SearchSourceQueryType,
	type SearchSourceQueryVariables,
} from '../../gql/search-source-query';
import type { CuratedDefinitionSearchSourcesFunctionType } from '../types';

import { getReferenceQueryOrLinkTitle } from './utils';

type SearchSourceSupplierType = {
	searchSources: CuratedDefinitionSearchSourcesFunctionType;
};

export const useSearchSourceSupplier = (): SearchSourceSupplierType => {
	const { commonAttributes } = useAIAnswerContext();
	const { cloudId, extraAttributes } = commonAttributes;
	const { readingAidsSessionId } = extraAttributes;

	const searchSources = useCallback(
		async (query: string) => {
			return fetchAggResponse<SearchSourceQueryVariables, SearchSourceQueryType>({
				graphQuery: SearchSourceQuery,
				variables: {
					experience: 'curated-definition-source-search',
					analytics: {
						searchSessionId: readingAidsSessionId as string,
					},
					query: getReferenceQueryOrLinkTitle(query),
					filters: {
						entities: ['ati:cloud:confluence:page', 'ati:cloud:confluence:blogpost'],
						locations: [cloudId ? `ari:cloud:confluence::site/${cloudId}` : ''],
						confluenceFilters: {
							titleMatchOnly: true,
						},
					},
					first: 5,
				},
			}).then((response) => {
				if (response.data) {
					return response.data.search.results.edges.map((edge) => ({
						id: edge.node?.id || '',
						type: edge.node?.type || '',
						title: edge.node?.title || '',
						container: edge.node?.space?.name || '',
						url: edge.node?.url || '',
						iconUrl: edge.node?.iconUrl || '',
						lastModified: edge.node?.lastModified || '',
					}));
				}
				return [];
			});
		},
		[cloudId, readingAidsSessionId],
	);

	return { searchSources };
};
