import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	edit_definition_modal_header_edit: {
		id: 'search_ai.edit_definition_modal_header_edit',
		defaultMessage: 'Edit definition',
		description: 'Header of the definition modal when user is editing a definition',
	},
	edit_definition_modal_header_create: {
		id: 'search_ai.edit_definition_modal_header_create',
		defaultMessage: 'Add a definition',
		description: 'Header of the definition modal when user is creating a definition',
	},
	edit_definition_text_box_label: {
		id: 'search_ai.edit_definition_text_box_label',
		defaultMessage: 'Definition',
		description: 'Label above edit definition text editor',
	},
	edit_definition_no_definition: {
		id: 'search_ai.edit_definition_no_definition',
		defaultMessage: 'Enter definition',
		description: 'Error text when user has not entered in definition',
	},
	edit_definition_source_label: {
		id: 'search_ai.edit_definition_source_label',
		defaultMessage: 'Source',
		description: 'Label above edit definition source',
	},
	edit_definition_add_source_label: {
		id: 'search_ai.edit_definition_add_source_label',
		defaultMessage: 'Add a source',
		description: 'Label above edit definition add source',
	},
	edit_definition_restricted_to_label: {
		id: 'search_ai.edit_definition_restricted_to_label',
		defaultMessage: 'Restricted to',
		description: 'Label above edit definition restricted to',
	},
	edit_definition_restricted_to_site_option: {
		id: 'search_ai.edit_definition_restricted_to_site_option',
		defaultMessage: 'Anyone on this site',
		description: 'Text for site restriction option',
	},
	edit_definition_source_search_box_placeholder: {
		id: 'search_ai.edit_definition_source_search_box_placeholder',
		defaultMessage: 'Search or paste a Confluence link',
		description: 'Placeholder for search source input box',
	},
	edit_definition_back_button: {
		id: 'search_ai.edit_definition_back_button',
		defaultMessage: 'Back',
		description: 'Text for back definition button',
	},
	edit_definition_update_button: {
		id: 'search_ai.edit_definition_update_button',
		defaultMessage: 'Update',
		description: 'Text for update definition button',
	},
	edit_definition_source_option_metadata_updated_text: {
		id: 'search_ai.edit_definition_source_option_metadata_updated_text',
		defaultMessage: 'Updated',
		description: 'Text of updated metadata of source option',
	},
});
