import crypto from 'crypto';

import { type NLPSearchResultSource } from '../../../ai-answer-dialog/types';

export const sha256Hash = (str: string): string =>
	crypto.createHash('sha256').update(str).digest('hex');

export const sanitizeSources = (sources: NLPSearchResultSource[]): { id: string; type: string }[] =>
	sources.map((source) => ({
		id: source.ari ?? source.id,
		type: source.type,
	}));
