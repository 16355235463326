import React, { type FC, useEffect, useState } from 'react';

import { FormattedMessage, FormattedRelativeTime } from 'react-intl-next';

import { WithCustomIntlProvider } from '@atlassian/mpt-utils';

import { fetchLanguageFile } from '../../utils/i18n';

import { messages } from './messages';

const SECOND = 1;
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = WEEK * 4.345;
const YEAR = MONTH * 12;

type Unit = 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second';

const units: [Unit, number][] = [
	['year', YEAR],
	['month', MONTH],
	['week', WEEK],
	['day', DAY],
	['hour', HOUR],
	['minute', MINUTE],
	['second', SECOND],
];

const getRelativeTimeProps = (timeDifferenceInSeconds: number) => {
	const relevantUnit =
		units.find((unit) => {
			return timeDifferenceInSeconds >= unit[1];
		}) || units[0];

	let props = {
		value: -Math.floor(timeDifferenceInSeconds / relevantUnit[1]),
		unit: relevantUnit[0],
	};

	// Only create the update interval if time difference in seconds, minutes or hours
	// FormattedRelativeTime no longer supports higher than 'hour' for interval update
	// read "Limitation" on https://formatjs.io/docs/react-intl/components/#formattedrelativetime
	if (['second', 'minute', 'hour'].includes(relevantUnit[0])) {
		return {
			...props,
			updateIntervalInSeconds: 60,
		};
	}

	return props;
};

export type TimeAgoProps = {
	date: number;
};

const TimeAgo: FC<TimeAgoProps> = ({ date }) => {
	const differenceInSeconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
	const isLessThanOneMinuteAgo = differenceInSeconds < 60;
	const [showJustNow, setShowJustNow] = useState<boolean>(isLessThanOneMinuteAgo);

	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout>;
		if (isLessThanOneMinuteAgo) {
			setShowJustNow(true);
			// gonna refresh only once after difference time has passed
			const refreshTime = (60 - differenceInSeconds) * 1000;
			timeout = setTimeout(() => {
				setShowJustNow(false);
			}, refreshTime);
		}
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [differenceInSeconds, isLessThanOneMinuteAgo]);

	if (showJustNow) {
		return <FormattedMessage {...messages.justNow} />;
	}

	return <FormattedRelativeTime numeric="always" {...getRelativeTimeProps(differenceInSeconds)} />;
};

export default WithCustomIntlProvider(TimeAgo, fetchLanguageFile);
