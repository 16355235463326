import React, { Fragment, type PropsWithChildren, Suspense, useCallback, useState } from 'react';

import { FormattedMessage } from 'react-intl-next';

import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { Provider } from '@atlaskit/smart-card';

import { useAIAnswerContext } from '../../../ai-answer-dialog/ai-answer-context';
import { onExpandButtonClicked } from '../../../ai-answer-dialog/analytics';
import { type NLPSearchResultSource } from '../../../ai-answer-dialog/types';
import { ErrorBoundary } from '../error-boundary';

import { messages } from './messages';
import { SourceCard } from './source-card';

const cardTitleStyles = xcss({
	marginTop: 'space.100',
	color: 'color.text.subtlest',
	fontSize: '12px',
	fontWeight: 600,
	lineHeight: '16px',
	letterSpacing: 0,
});

const containerStyles = xcss({
	width: '100%',
	marginTop: 'space.075',
});

const freeflowLinks = xcss({
	display: 'inline',
	marginRight: 'space.075',
	lineHeight: 2,
});

const iconWrapperStyles = xcss({
	width: '24px',
	height: '24px',
	cursor: 'pointer',
});

const Sources = ({
	sources,
	searchSessionId,
	onClickHandler,
	allowFreeflowLayout,
	verticalLayout,
	enableSmartLinkSources = true,
}: {
	sources: NLPSearchResultSource[];
	searchSessionId: string;
	onClickHandler: (index: number, hostname?: string) => void;
	allowFreeflowLayout: boolean;
	verticalLayout: boolean;
	enableSmartLinkSources?: boolean;
}) => {
	const [showAllSources, setShowAllSources] = useState(false);
	const { fireAnalyticsEvent, commonAttributes } = useAIAnswerContext();
	const canExpand = sources.length > 1 && verticalLayout;
	const visibleSources = showAllSources || !verticalLayout ? sources : sources.slice(0, 1);

	const onExpandButtonClick = useCallback(() => {
		fireAnalyticsEvent(
			onExpandButtonClicked({ ...commonAttributes, expandButtonSource: 'sources' }),
		);
		setShowAllSources(!showAllSources);
	}, [fireAnalyticsEvent, commonAttributes, showAllSources]);

	return (
		<Fragment>
			{visibleSources.map((source, index) => (
				<Inline
					key={`ai-answer-source-${index}`}
					xcss={allowFreeflowLayout ? freeflowLinks : null}
					space="space.050"
					alignBlock="center"
				>
					<SourceCard
						index={index}
						key={`search-ai-answer-source-${index}`}
						source={source}
						searchSessionId={searchSessionId}
						onClickHandler={onClickHandler}
						enableSmartLinkSources={enableSmartLinkSources}
					/>
					{index === 0 && canExpand && (
						<Box xcss={iconWrapperStyles} onClick={onExpandButtonClick}>
							{showAllSources ? (
								<ChevronDownIcon color="currentColor" label="" testId="collapse-sources-icon" />
							) : (
								<ChevronRightIcon color="currentColor" label="" testId="expand-sources-icon" />
							)}
						</Box>
					)}
				</Inline>
			))}
		</Fragment>
	);
};

const LayoutWrapper = (
	props: PropsWithChildren<{ allowFreeflowLayout: boolean; verticalLayout: boolean }>,
) => {
	if (props.allowFreeflowLayout) {
		return <div>{props.children}</div>;
	} else if (props.verticalLayout) {
		return <Stack space="space.075">{props.children}</Stack>;
	} else {
		return (
			<Inline space="space.075" shouldWrap>
				{props.children}
			</Inline>
		);
	}
};

type AISourcesProps = {
	sources: NLPSearchResultSource[];
	searchSessionId: string;
	title?: {
		id: string;
		description: string;
		defaultMessage: string;
	};
	onClickHandler?: (index: number, hostname?: string) => void;
	allowFreeflowLayout?: boolean;
	verticalLayout?: boolean;
	limit?: number;
	enableSmartLinkSources?: boolean;
};

export const AISources = (props: AISourcesProps) => {
	const {
		sources: ogSources,
		searchSessionId,
		title,
		onClickHandler = () => {},
		allowFreeflowLayout = false,
		verticalLayout = false,
		limit,
		enableSmartLinkSources,
	} = props;
	const sourcesTitle = title ?? messages.sources_title;

	const sources = limit ? ogSources.slice(0, limit) : ogSources;

	const MaybeSmartCardProvider = enableSmartLinkSources !== false ? Provider : Fragment;

	if (!sources.length) {
		return <></>;
	}

	return (
		<ErrorBoundary fallback={<></>}>
			<Suspense fallback={<></>}>
				<Box xcss={cardTitleStyles}>
					<FormattedMessage {...sourcesTitle} values={{ numSources: sources.length }} />
				</Box>
				<Box xcss={containerStyles}>
					<LayoutWrapper allowFreeflowLayout={allowFreeflowLayout} verticalLayout={verticalLayout}>
						<MaybeSmartCardProvider>
							<Sources
								sources={sources}
								searchSessionId={searchSessionId}
								onClickHandler={onClickHandler}
								allowFreeflowLayout={allowFreeflowLayout}
								verticalLayout={verticalLayout}
								enableSmartLinkSources={enableSmartLinkSources}
							/>
						</MaybeSmartCardProvider>
					</LayoutWrapper>
				</Box>
			</Suspense>
		</ErrorBoundary>
	);
};
