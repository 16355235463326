import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Messages for the AI Answer Component
	 */
	ai_answer_component_initial_title_text: {
		id: 'search_ai.ai_answer_component.initial_title_text',
		defaultMessage:
			'Ask questions in search and get answers based on the content you have access to',
		description: 'Initial title text for the ai answer component',
	},
	ai_answer_component_beta_status_label: {
		id: 'search_ai.ai_answer_component.beta_status_label',
		defaultMessage: 'Beta',
		description: 'Status text for the beta lozenge',
	},
	ai_answer_component_title_text: {
		id: 'search_ai.ai_answer_component.title_text',
		defaultMessage: 'Atlassian Intelligence',
		description: 'Title text for the AI Answer component in the answered and loading states',
	},
	ai_answer_component_title_text_loading: {
		id: 'search_ai.ai_answer_component.title_text_loading',
		defaultMessage: 'is working...',
		description: 'Title text for the AI Answer component while loading',
	},
	ai_answer_component_title_text_loading_reading_aids: {
		id: 'search_ai.ai_answer_component.title_text_loading_reading_aids',
		defaultMessage: 'Generating',
		description: 'Title text for the AI Answer component while loading for Reading Aids',
	},
	ai_answer_component_title_text_loaded: {
		id: 'search_ai.ai_answer_component.title_text_loaded',
		defaultMessage: 'Answered by',
		description: 'Title text for the AI Answer component when loaded',
	},
	ai_answer_component_button_text: {
		id: 'ai_answer_component_button_text',
		defaultMessage: 'Ask AI',
		description: 'Button text to fetch an AI answer',
	},
	ai_answer_component_result_error_text: {
		id: 'search_ai.ai_answer_component.result_error_text',
		defaultMessage:
			"{brandName} couldn't generate an answer for you. Try rephrasing or explore using filters in {advancedSearchLink}.",
		description: 'Text for when the AI Answer API returns no results or an error',
	},
	ai_answer_component_result_error_advanced_search_link: {
		id: 'search_ai.ai_answer_component.result_error_advanced_search_link',
		defaultMessage: 'advanced search',
		description: 'Link text for navigating to advanced search from the result error message',
	},
	ai_answer_component_source_label: {
		id: 'search_ai.ai_answer_component.source_label',
		defaultMessage: '{numSources, plural, =1 {Source} other {Sources}}',
		description: 'Label text for the sources section of the AI Answer component',
	},
	ai_answer_component_reference_label: {
		id: 'search_ai.ai_answer_component.reference_label',
		defaultMessage: '{numSources, plural, =1 {Source} other {Sources}}',
		description: 'Label text for the sources section of the AI Answer component',
	},
	ai_answer_component_source_page_icon_label: {
		id: 'search_ai.ai_answer_component.source_page_icon_label',
		defaultMessage: 'Source Page Icon',
		description: 'Label text for the page icon for the sources in the AI Answer component',
	},
	ai_answer_expand_button_label: {
		id: 'search_ai.ai_answer_component.expand_button_label',
		defaultMessage: 'Show more',
		description: 'Label text for button used to expand the AI Answer component content',
	},
	ai_answer_collapse_button_label: {
		id: 'search_ai.ai_answer_component.collapse_button_label',
		defaultMessage: 'Show less',
		description: 'Label text for button used to collapse the AI Answer component content',
	},
	ai_answer_component_information_icon_tooltip_text: {
		id: 'search_ai.ai_answer_component_information_icon_tooltip_text',
		defaultMessage: 'Information quality may vary. {informationLink}',
		description: 'Tooltip text with a description of the beta feature AI generated search answers',
	},
	ai_answer_component_information_icon_tooltip_link: {
		id: 'search_ai.ai_answer_component_information_icon_tooltip_link',
		defaultMessage: 'Learn why',
		description: 'Link text to the page to learn more about Atlassian Intelligence',
	},
	ai_answer_component_feedback_label: {
		id: 'search_ai.ai_answer_component_feedback_label',
		defaultMessage: 'Was this helpful?',
		description: 'Label text for the ai answer component feedback component',
	},
	ai_answer_component_like_button_tooltip: {
		id: 'search_ai.ai_answer_component_like_button_tooltip',
		defaultMessage: 'Good response',
		description: 'Tooltip text for the ai answer component like button',
	},
	ai_answer_component_dislike_button_tooltip: {
		id: 'search_ai.ai_answer_component_dislike_button_tooltip',
		defaultMessage: 'Bad response',
		description: 'Tooltip text for the ai answer component dislike button',
	},
	ai_answer_component_report_button: {
		id: 'search_ai.ai_answer_component_report_button',
		defaultMessage: 'Report this',
		description: 'Button text for reporting an issue with the ai answer',
	},
	ai_answer_component_copy_button: {
		id: 'search_ai.ai_answer_component_copy_button',
		defaultMessage: 'Copy',
		description: 'Button text for copying the ai answer string result',
	},
	ai_answer_component_report_success_text: {
		id: 'search_ai.ai_answer_component_report_success_text',
		defaultMessage: 'Report submitted',
		description: 'Text to indicate that a report was successfully submitted',
	},
	ai_answer_component_report_modal_title: {
		id: 'search_ai.ai_answer_component_report_modal_title',
		defaultMessage: 'Help us improve Atlassian Intelligence',
		description: 'Label text for the summary section of the report feedback collector',
	},
	ai_answer_component_report_summary_label: {
		id: 'search_ai.ai_answer_component_report_summary_label',
		defaultMessage: 'Was there a problem with the AI response? Let us know what happened.',
		description: 'Label text for the summary section of the report feedback collector',
	},
	ai_answer_component_report_empty_option_label: {
		id: 'search_ai.ai_answer_component_report_empty_option_label',
		defaultMessage: 'I want to report...',
		description: 'Label text for the empty feedback type option of the report feedback collector',
	},
	ai_answer_component_report_empty_option_share_label: {
		id: 'search_ai.ai_answer_component_report_empty_option_share_label',
		defaultMessage: 'I want to share...',
		description: 'Label text for the empty feedback type option of the report feedback collector',
	},
	ai_answer_component_report_consent_checkbox_label: {
		id: 'search_ai.ai_answer_component_report_consent_checkbox_label',
		defaultMessage: 'Include my last prompt and response in the feedback.',
		description:
			'Label for the checkbox asking for user consent to include their AI prompt and response in their feedback',
	},
	ai_answer_component_topics_not_relevant_checkbox_label: {
		id: 'search_ai.ai_answer_component_topics_not_relevant_checkbox_label',
		defaultMessage: 'The topics are not relevant to this person.',
		description:
			'Label for the checkbox asking if related topics shown for the person are relevant',
	},
	ai_answer_component_report_relevance_option_label: {
		id: 'search_ai.ai_answer_component_report_relevance_option_label',
		defaultMessage: 'This isn’t relevant or isn’t what I expected',
		description:
			'Label text for the relevance feedback type option of the report feedback collector',
	},
	ai_answer_component_report_inaccurate_option_label: {
		id: 'search_ai.ai_answer_component_report_inaccurate_option_label',
		defaultMessage: 'This is inaccurate or misleading',
		description:
			'Label text for the inaccurate feedback type option of the report feedback collector',
	},
	ai_answer_component_report_offensive_option_label: {
		id: 'search_ai.ai_answer_component_report_offensive_option_label',
		defaultMessage: 'This is offensive or harmful',
		description:
			'Label text for the offensive feedback type option of the report feedback collector',
	},
	ai_answer_component_report_other_option_label: {
		id: 'search_ai.ai_answer_component_report_other_option_label',
		defaultMessage: 'Something else',
		description: 'Label text for the other feedback type option of the report feedback collector',
	},
	ai_answer_component_report_details_label: {
		id: 'search_ai.ai_answer_component_report_details_label',
		defaultMessage: 'Describe the issue',
		description: 'Label text for the summary text box for the report feedback collector',
	},
	ai_answer_component_feedback_flag_description: {
		id: 'search_ai.ai_answer_component_feedback_flag_descriptionl',
		defaultMessage: 'We appreciate you taking the time to send this feedback',
		description: 'Description text for the feedback flag in the ai answer component',
	},
	ai_answer_component_summary_placeholder_label: {
		id: 'search_ai.ai_answer_component_summary_placeholder_label',
		defaultMessage: 'Explain the problem you experienced',
		description: 'Label text for the summary placeholder for the report feedback collector',
	},
	ai_answer_component_followups_label: {
		id: 'search_ai.ai_answer_component_followups_label',
		defaultMessage: 'Ask a follow up question',
		description: 'Label text for the ai answer component suggested follow-ups component',
	},
	ai_answer_component_error_state_subjective_query: {
		id: 'search_ai.ai_answer_component_error_state_subjective_query',
		defaultMessage:
			'That seems like a subjective question, so {brandName} can’t answer it for you right now.',
		description: 'Error state text displayed when there is a subjective query',
	},
	ai_answer_component_error_state_keyword: {
		id: 'search_ai.ai_answer_component_error_state_keyword',
		defaultMessage:
			'{brandName} couldn’t understand your question, possibly because it didn’t include enough details. Try rephrasing your question, or including some more specific details. {learnMoreLink}',
		description: 'Error state text displayed when insufficient keywords',
	},
	ai_answer_component_error_state_acceptable_use: {
		id: 'search_ai.ai_answer_component_error_state_acceptable_use',
		defaultMessage:
			'{brandName} can’t provide you with a response, as it might go against {acceptableUseLink}. Please try asking another question.',
		description:
			'Error state text displayed when the question goes against the acceptable use policy',
	},
	ai_answer_component_error_state_acceptable_use_link: {
		id: 'search_ai.ai_answer_component_error_state_acceptable_use_link',
		defaultMessage: "Atlassian's acceptable use policy",
		description: 'Link for acceptable use policy',
	},
	ai_answer_component_error_state_network: {
		id: 'search_ai.ai_answer_component_error_state_network',
		defaultMessage:
			"{brandName} can't provide a response right now. Read more in our {supportLink}.",
		description: 'Error state text when there is a network error',
	},
	ai_answer_component_error_state_no_answer: {
		id: 'search_ai.ai_answer_component_error_state_no_answer',
		defaultMessage:
			'{brandName} doesn’t have enough data to provide a response right now. It’s possible that we couldn’t find the relevant information in your company’s knowledge base. Try rephrasing your question, or including some more specific details. {learnMoreLink}',
		description: 'Error state text displayed when there is no answer from the BE',
	},
	ai_answer_component_open_ai_rate_limit_error: {
		id: 'search_ai.ai_answer_component_open_ai_rate_limit_error',
		defaultMessage:
			'Excessive use has been detected with {brandName}. Try again later. Read more in our {supportLink}.',
		description: 'Error state text displayed when there is a rate limiting applied for OpenAI',
	},
	ai_answer_component_support_link: {
		id: 'search_ai.ai_answer_component_support_link',
		defaultMessage: 'support documentation',
		description: 'Link text for support documentation',
	},
	ai_answer_component_error_state_no_who_is_answer: {
		id: 'search_ai.ai_answer_component_error_state_no_who_is_answer',
		defaultMessage:
			'{brandName} couldn’t find a match for this person in your organisation. It’s possible that there was a misspelling in your search, this person goes by another name, or that this person doesn’t work here.',
		description:
			'Error state text displayed when there is no answer from the BE for a who is question',
	},
	ai_answer_component_disclaimer_who_question: {
		id: 'search_ai.ai_answer_component_disclaimer_who_question',
		defaultMessage:
			'This information is solely based on what’s in your knowledge base and may not always be accurate.',
		description: 'Text for the disclaimer in the case that a who question is asked',
	},
	ai_answer_permission_label: {
		id: 'search_ai.ai_answer_permission_label',
		defaultMessage:
			'Answers may include information from pages that are restricted to others but visible to you.',
		description: 'Text for a permission disclaimer label for the ai answer component',
	},
	ai_answer_dialog_cancel_label: {
		id: 'search_ai.ai_answer_dialog_cancel_label',
		defaultMessage: 'Dive deeper into the {searchResultsLink}',
		description: 'Text for a label to cancel the ai answer dialog',
	},
	ai_answer_dialog_search_results_label: {
		id: 'search_ai.ai_answer_dialog_search_results_label',
		defaultMessage: 'search results',
		description: 'Text for a search results link to be clicked on to cancel the ai answer dialog',
	},
	ai_answer_onboarding_banner_title: {
		id: 'search_ai.ai_answer_onboarding_banner_title',
		defaultMessage: 'Try Atlassian Intelligence in search',
		description: 'Title text for the ai answer onboarding banner',
	},
	ai_answer_onboarding_banner_v1_info: {
		id: 'search_ai.ai_answer_onboarding_banner_v1_info',
		defaultMessage: '<b>Example:</b>How do I submit expenses for reimbursement?',
		description: 'Info text for the ai answer v1 onboarding banner',
	},
	ai_answer_onboarding_banner_info: {
		id: 'search_ai.ai_answer_onboarding_banner_info',
		defaultMessage:
			'Ask about a project or topic within your company’s knowledge base. {informationLink}',
		description: 'Info text for the ai answer onboarding banner',
	},
	ai_answer_onboarding_banner_tooltip_link: {
		id: 'search_ai.ai_answer_onboarding_banner_tooltip_link',
		defaultMessage: 'Learn more',
		description: 'Link text to the page to learn more about Atlassian Intelligence',
	},
	ai_answer_onboarding_banner_example_query_vacation_policy: {
		id: 'search_ai.ai_answer_onboarding_banner_example_query_vacation_policy',
		defaultMessage: 'What is the vacation policy?',
		description: 'Example query about a vacation policy for ai search',
	},
	ai_answer_onboarding_banner_example_query_expenses: {
		id: 'search_ai.ai_answer_onboarding_banner_example_query_expenses',
		defaultMessage: 'How to submit expenses for reimbursement?',
		description: 'Example query about reimbursing expenses for ai search',
	},
	ai_answer_dialog_loaded_footer: {
		id: 'search_ai.ai_answer_dialog_loaded_footer',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description: 'Footer text for a loaded search ai dialog',
	},
	ai_answer_dialog_footer_more_options_tooltip: {
		id: 'search_ai.ai_answer_dialog_footer_more_options_tooltip',
		defaultMessage: 'More options',
		description: 'Tooltip text for the more options button in the ai answer dialog footer',
	},
	ai_answer_dialog_updated: {
		id: 'search_ai.ai_answer_dialog_updated',
		defaultMessage: 'Updated',
		description: 'Text shown next to date strings in result metadata',
	},
	ai_answer_who_questions_frequent_locations_header: {
		id: 'search_ai.ai_answer_who_questions_frequent_locations_header',
		defaultMessage: 'Frequent locations',
		description: 'Header for frequent locations section in who question answer',
	},
	ai_answer_who_questions_frequent_collaborators_header: {
		id: 'search_ai.ai_answer_who_questions_frequent_collaborators_header',
		defaultMessage: 'Frequent collaborators',
		description: 'Header for frequent collaborators section in who question answer',
	},
	ai_answer_who_question_team_section_member_count: {
		id: 'search_ai.ai_answer_who_question_team_section_member_count',
		defaultMessage: '{count, plural, =0 {No members} one {# member} other {# members}}',
		description: 'Text to show how many members are in the particular team',
	},
	ai_answer_who_questions_teams_header: {
		id: 'search_ai.ai_answer_who_questions_teams_header',
		defaultMessage: 'Teams',
		description: 'Header for teams section in who question answer',
	},
	ai_answer_who_questions_view_profile: {
		id: 'search_ai.ai_answer_who_questions_view_profile',
		defaultMessage: 'View profile',
		description: 'View profile link for who question answers',
	},
	ai_answer_who_questions_hover_role_icon: {
		id: 'search_ai.ai_answer_who_questions_hover_role_icon',
		defaultMessage: 'Role',
		description: 'Tooltip message for role icon',
	},
	ai_answer_who_questions_hover_department_icon: {
		id: 'search_ai.ai_answer_who_questions_hover_department_icon',
		defaultMessage: 'Department',
		description: 'Tooltip message for department icon',
	},
	ai_answer_who_questions_hover_location_icon: {
		id: 'search_ai.ai_answer_who_questions_hover_location_icon',
		defaultMessage: 'Location',
		description: 'Tooltip message for location icon',
	},
	ai_answer_who_questions_hover_local_time_icon: {
		id: 'search_ai.ai_answer_who_questions_hover_local_time_icon',
		defaultMessage: 'Local time',
		description: 'Tooltip message for local time icon',
	},
	ai_answer_who_questions_hover_email_icon: {
		id: 'search_ai.ai_answer_who_questions_hover_email_icon',
		defaultMessage: 'Email',
		description: 'Tooltip message for email icon',
	},
	ai_answer_who_questions_footer_header: {
		id: 'search_ai.ai_answer_who_questions_footer_header',
		defaultMessage: '{numMatches} additional {numMatches, plural, =1 {match} other {matches}}',
		description: 'Header for additional who result matches',
	},
	ai_answer_dialog_footer_content_quality_short: {
		id: 'search_ai.ai_answer_dialog_footer_content_quality_short',
		defaultMessage: 'Content quality may vary',
		description: 'Footer disclose - short version',
	},
	ai_answer_dialog_footer_content_quality_long: {
		id: 'search_ai.ai_answer_dialog_footer_content_quality_long',
		defaultMessage: 'Content is based on your permissions, and its quality may vary',
		description: 'Footer disclose - long version',
	},
	reading_aids_dialog_information_icon_tooltip_link: {
		id: 'search_ai.reading_aids_dialog_information_icon_tooltip_link',
		defaultMessage: 'Learn more',
		description:
			'Link text to the page to learn more about Atlassian Intelligence for reading aids',
	},
	reading_aids_dialog_loaded_footer: {
		id: 'search_ai.reading_aids_dialog_loaded_footer',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description: 'Footer text for a loaded reading aids dialog',
	},
	reading_aids_dialog_disclaimer: {
		id: 'search_ai.reading_aids_dialog_disclaimer',
		defaultMessage: 'Information may vary.',
		description: 'Disclaimer text for a loaded reading aids dialog',
	},
	reading_aids_dialog_disclaimer_link: {
		id: 'search_ai.reading_aids_dialog_disclaimer_link',
		defaultMessage: 'Learn why',
		description: 'Disclaimer link text for a loaded reading aids dialog',
	},
	reading_aids_defined_by: {
		id: 'search_ai.reading_aids_dialog_defined_by',
		defaultMessage: 'Defined by',
		description: 'Defined by text for when reading aids was edited',
	},
	reading_aids_see_edit_history: {
		id: 'search_ai.reading_aids_dialog_see_edit_history',
		defaultMessage: 'See all edit history',
		description: 'Text for button to see all edit history',
	},
	ai_answer_admin_message: {
		id: 'search_ai.ai_answer_admin_message',
		defaultMessage:
			'Unleash the knowledge in your Confluence by asking questions in search and getting answers from Atlassian Intelligence.',
		description: 'Default message in admin onboarding banner',
	},
	ai_answer_admin_turn_on_atlassian_intelligence: {
		id: 'search_ai.ai_admin_turn_on_atlassian_intelligence',
		defaultMessage: 'Turn on Atlassian Intelligence',
		description: 'Link to take user to Admin Hub',
	},
	ai_answer_admin_info_popup_header: {
		id: 'search_ai.ai_answer_admin_info_popup_header',
		defaultMessage: 'Get answers from Atlassian Intelligence in search',
		description: 'Default header of admin info popup',
	},
	ai_answer_admin_info_popup_link: {
		id: 'search_ai.ai_answer_admin_info_popup_link',
		defaultMessage: 'Learn more about Atlassian Intelligence',
		description:
			'Link text to the page to learn more about Atlassian Intelligence in admin info popup',
	},
	ai_answer_admin_info_popup_dismiss: {
		id: 'search_ai.ai_answer_admin_info_popup_dismiss',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button for admin popup',
	},
	ai_answer_generating_text: {
		id: 'search_ai.ai_answer_generating_text',
		defaultMessage: 'Generating',
		description: 'Text for loading state of streaming',
	},
	ai_answer_generating_rovo: {
		id: 'ai_answer_generating_rovo',
		defaultMessage: 'Generating',
		description: 'Rovo text for loading state of smart answer ',
	},
	ai_answer_generating_icon_alt_text: {
		id: 'search_ai.ai_answer_generating_icon_alt_text',
		defaultMessage: 'Generating icon',
		description: 'Alt text of the generating icon',
	},
	ai_answer_button_tooltip_message: {
		id: 'search_ai_answer_prompt.ask_ai_button.tooltip',
		defaultMessage: 'Ask a question in search to get an answer from Atlassian Intelligence',
		description: 'Tooltip text for the Ask AI button',
	},
	ai_answer_reading_aids_banner_header: {
		id: 'search_ai.reading_aids.banner_header',
		defaultMessage: 'Define any term with AI, not just acronyms',
		description: 'Header text for the reading aids banner',
	},
	ai_answer_reading_aids_banner_description: {
		id: 'search_ai.reading_aids.banner_description',
		defaultMessage: `Get definitions for project terms, team names and more. Just highlight the word or phrase, then select <b>Define</b>.`,
		description: 'Description text for the reading aids banner',
	},
	reading_aids_edited_definition_report_modal_title: {
		id: 'search_ai.reading_aids_edited_definition_report_modal_title',
		defaultMessage: 'Help us improve Definitions',
		description: 'Label text for the title of the report feedback collector for edited definition',
	},
	reading_aids_edited_definition_report_label: {
		id: 'search_ai.reading_aids_edited_definition_report_label',
		defaultMessage: 'Was there a problem with this definition? Let us know what happened.',
		description:
			'Label text for the summary section of the report feedback collector for edited definition',
	},
	knowledge_cards_view_team: {
		id: 'search_ai.knowledge_cards.view_team',
		defaultMessage: 'View team page',
		description: 'View profile link for teams',
	},
	knowledge_cards_team_subheader: {
		id: 'search_ai.knowledge_cards.team_subheader',
		defaultMessage: 'Team',
		description: 'Subheader for team in knowledge cards',
	},
	knowledge_cards_copy_email_address: {
		id: 'search_ai.knowledge_cards.copy_email_address',
		defaultMessage: 'Copy email address',
		description: 'Hover text for email button',
	},
	knowledge_cards_recent_work_subheader: {
		id: 'search_ai.knowledge_cards.recent_work_subheader',
		defaultMessage: 'Recent work',
		description: 'Recent work subheader',
	},
	knowledge_cards_collaborators_subheader: {
		id: 'search_ai.knowledge_cards.collaborators_subheader',
		defaultMessage: 'Collaborators',
		description: 'Collaborators subheader',
	},
	knowledge_cards_recent_work_empty: {
		id: 'search_ai.knowledge_cards.recent_work_empty',
		defaultMessage: 'No recent work',
		description: 'Recent work empty state text',
	},
	knowledge_cards_collaborators_empty: {
		id: 'search_ai.knowledge_cards.collaborators_empty',
		defaultMessage: 'No recent collaborators',
		description: 'Collaborators empty state text',
	},
	knowledge_cards_teammembers_empty: {
		id: 'search_ai.knowledge_cards.teammembers_empty',
		defaultMessage: 'No team members',
		description: 'Team members empty state text',
	},
	knowledge_cards_view_all_link: {
		id: 'search_ai.knowledge_cards.view_all_link',
		defaultMessage: 'View all',
		description: 'View all link',
	},
	knowledge_cards_team_members_subheader: {
		id: 'search_ai.knowledge_cards.team_members_subheader',
		defaultMessage: 'Team members',
		description: 'Team members subheader',
	},
});
