// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserInfoWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
	height: '100%',
	width: '100%',
	marginLeft: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserImg = styled.img({
	width: '72px',
	height: '72px',
	borderRadius: '13px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserHeader = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserName = styled.span({
	fontWeight: 500,
	fontSize: '20px',
	lineHeight: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserInfoTextWrapper = styled.span({
	marginTop: token('space.025', '2px'),
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserInfo = styled.span({
	display: 'flex',
	alignItems: 'center',
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserInfoText = styled.span({
	fontWeight: 400,
	fontSize: '14px',
	lineHeight: '20px',
	color: token('color.text.subtlest', '#626F86'),
	marginLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryUserAddtlIconPadding = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		paddingTop: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProfileSectionPrimaryViewProfileLinkWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
});
