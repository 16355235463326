import {
	NLPSearchJSONResultFormat,
	type NLPSearchJSONResultType,
	type NLPSearchPersonResultType,
	type NLPSearchTeamResultType,
	type NLPSearchWhoResultCollaboratorDataType,
	type NLPSearchWhoResultContainerDataType,
	type NLPSearchWhoResultTeamDataType,
	type NLPSearchWhoResultType,
	type NLPSearchWorkResultRecentActivityDataType,
	type NLPSearchWorkResultType,
} from '../types';

import {
	type ActivityOutputJson,
	type AdditionalMatch,
	type ProfileOutputJson,
	type RecentActivityType,
	type TeamType,
} from './types';

export const getTypedJSONResultData = (
	answerString: string,
): {
	parsedResult: NLPSearchJSONResultType;
	parsedType: NLPSearchJSONResultFormat | undefined;
} => {
	const parsed: NLPSearchJSONResultType = JSON.parse(answerString);
	if ('user_metadata' in parsed && !!parsed.user_metadata) {
		return {
			parsedResult: parsed.user_metadata as NLPSearchWhoResultType['user_metadata'],
			parsedType: NLPSearchJSONResultFormat.WHO,
		};
	} else if ('user_metadata_only_cards' in parsed && !!parsed.user_metadata_only_cards) {
		return {
			parsedResult:
				parsed.user_metadata_only_cards as NLPSearchPersonResultType['user_metadata_only_cards'],
			parsedType: NLPSearchJSONResultFormat.PERSON,
		};
	} else if ('user_metadata_with_activity' in parsed && !!parsed.user_metadata_with_activity) {
		return {
			parsedResult:
				parsed.user_metadata_with_activity as NLPSearchWorkResultType['user_metadata_with_activity'],
			parsedType: NLPSearchJSONResultFormat.WORK,
		};
	} else if ('team_metadata' in parsed && !!parsed.team_metadata) {
		return {
			parsedResult: parsed.team_metadata as NLPSearchTeamResultType['team_metadata'],
			parsedType: NLPSearchJSONResultFormat.TEAM,
		};
	} else {
		// This line should never be reached, this means that an unsupported type was in the JSON result
		return { parsedResult: parsed, parsedType: undefined };
	}
};

const mapRecentActivity = (
	activity: RecentActivityType,
): NLPSearchWorkResultRecentActivityDataType => ({
	title: activity.title,
	url: activity.url,
	containerName: activity.containerName,
	event: activity.event as NLPSearchWorkResultRecentActivityDataType['event'],
	timestamp: activity.timestamp,
	iconUrl: activity.iconUrl,
	contributors: activity.contributors.slice(0, MAX_OTHERS_TO_SHOW).map((contributor) => ({
		name: contributor.name,
		id: contributor.accountId,
	})),
	othersCount: activity.othersCount,
});

const mapTeam = (team: TeamType): NLPSearchWhoResultTeamDataType => ({
	name: team.displayName,
	avatarUrl: team.smallAvatarImageUrl,
	url: `/wiki/people/team/${team.id}`,
	memberCount: team.memberCount,
});

const mapAdditionalMatch = (
	match: AdditionalMatch,
): {
	name: string;
	avatar_url: string;
	profile_url: string;
	job_title: string;
	email: string;
} => {
	return {
		name: match.name,
		avatar_url: match.avatar_url,
		profile_url: match.profile_url,
		job_title: match.title,
		email: match.email ?? '',
	};
};

// Collaborators, contributors and additional matches are expected to be at most 3
const MAX_OTHERS_TO_SHOW = 3;

export const getTypedJSONResultDataAssistanceService = (
	answerString: string,
): {
	parsedResult: NLPSearchJSONResultType;
	parsedType: NLPSearchJSONResultFormat | undefined;
} => {
	const parsed: ActivityOutputJson | ProfileOutputJson | NLPSearchTeamResultType =
		JSON.parse(answerString);

	if ('enriched_activity_output' in parsed) {
		const { main_user_profile, additional_matches } = parsed.enriched_activity_output;

		return {
			parsedResult: [
				{
					name: main_user_profile.user_base_data.name,
					email: main_user_profile.user_base_data.email,
					time: main_user_profile.time,
					profile_url: main_user_profile.user_base_data.profile_url,
					avatar_url: main_user_profile.user_base_data.avatar_url,
					recentActivity: main_user_profile.recent_activity.map(mapRecentActivity),
					department: main_user_profile.user_base_data.extended_profile?.department,
					job_title: main_user_profile.user_base_data.extended_profile?.job_title,
					location: main_user_profile.user_base_data.extended_profile?.location,
					organization: main_user_profile.user_base_data.extended_profile?.organization,
					viewAllLink: main_user_profile.view_all_activity_url,
				},
				...additional_matches.slice(0, MAX_OTHERS_TO_SHOW).map((x) => ({
					...mapAdditionalMatch(x),
					recentActivity: [],
					viewAllLink: '',
				})),
			],
			parsedType: NLPSearchJSONResultFormat.WORK,
		};
	} else if ('enriched_profile_output' in parsed) {
		const { main_user_profile, additional_matches } = parsed.enriched_profile_output;

		return {
			parsedResult: [
				{
					account_id: main_user_profile.user_base_data.account_id,
					name: main_user_profile.user_base_data.name,
					email: main_user_profile.user_base_data.email,
					profile_url: main_user_profile.user_base_data.profile_url,
					avatar_url: main_user_profile.user_base_data.avatar_url,
					job_title: main_user_profile.user_base_data.extended_profile?.job_title,
					location: main_user_profile.user_base_data.extended_profile?.location,
					department: main_user_profile.user_base_data.extended_profile?.department,
					organization: main_user_profile.user_base_data.extended_profile?.organization,
					time: main_user_profile.time,
					collaborators: main_user_profile.collaborators.slice(0, 6).map(
						(user): NLPSearchWhoResultCollaboratorDataType => ({
							name: user.name,
							picture: user.avatar_url,
							title: user.extended_profile?.job_title ?? null,
							url: user.profile_url,
							accountId: user.account_id,
							department: user.extended_profile?.department ?? null,
							email: user.email,
						}),
					),
					containers: main_user_profile.collaboration_spaces.map(
						(container): NLPSearchWhoResultContainerDataType => ({
							name: container.name,
							containerType: container.container_type,
							url: container.url,
							iconUrl: container.icon_url,
						}),
					),
					recentActivity: main_user_profile.recent_activity?.map(mapRecentActivity),
					viewAllLink: main_user_profile.view_all_activity_url,
					teams: main_user_profile.teams?.map(mapTeam),
				},
				...additional_matches.slice(0, MAX_OTHERS_TO_SHOW).map(mapAdditionalMatch),
			],
			parsedType: NLPSearchJSONResultFormat.WHO,
		};
	} else if ('team_metadata' in parsed) {
		return {
			parsedResult: parsed.team_metadata,
			parsedType: NLPSearchJSONResultFormat.TEAM,
		};
	} else {
		// This line should never be reached, this means that an unsupported type was in the JSON result
		return {
			parsedResult: {} as NLPSearchJSONResultType,
			parsedType: undefined,
		};
	}
};
