import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	readingAidsEditHistoryHeader: {
		id: 'contextual-reading-aids.edit-history.header.text',
		defaultMessage: 'Edit History',
		description: 'Header text for Reading Aids edit history modal',
	},
	readingAidsEditHistoryDescription: {
		id: 'contextual-reading-aids.edit-history.description.text',
		defaultMessage: 'See all the edits for {queryText}',
		description: 'Description text for Reading Aids edit history modal',
	},
	readingAidsEditHistoryCardEdited: {
		id: 'contextual-reading-aids.edit-history.card.edited',
		defaultMessage: '{editorName} edited',
		description: 'Edited text in Reading Aids edit history card',
	},
	readingAidsEditHistoryCardErrorText: {
		id: 'contextual-reading-aids.edit-history.card.error-text',
		defaultMessage: 'An error has occurred. Please try again later.',
		description: 'Error text for Reading Aids edit history',
	},
	readingAidsEditHistoryVisibilityTextOrg: {
		id: 'contextual-reading-aids.edit-history.visibility.text.org',
		defaultMessage: 'This definition is visible to anyone in the entire organization',
		description: 'Org visibility text for Reading Aids edit history',
	},
	readingAidsEditHistoryVisibilitySpace: {
		id: 'contextual-reading-aids.edit-history.visibility.text.space',
		defaultMessage: 'This definition is visible to anyone in this space',
		description: 'Space visibility text for Reading Aids edit history',
	},
	readingAidsEditHistoryVisibilityPage: {
		id: 'contextual-reading-aids.edit-history.visibility.text.page',
		defaultMessage: 'This definition is visible to anyone viewing this page',
		description: 'Page visibility text for Reading Aids edit history',
	},
	readingAidsEditHistoryVisibilityBlog: {
		id: 'contextual-reading-aids.edit-history.visibility.text.blog',
		defaultMessage: 'This definition is visible to anyone viewing this blog',
		description: 'Blog visibility text for Reading Aids edit history',
	},
	readingAidsEditHistoryVisibilityProject: {
		id: 'contextual-reading-aids.edit-history.visibility.text.Project',
		defaultMessage: 'This definition is visible to anyone viewing this project',
		description: 'Project visibility text for Reading Aids edit history',
	},
});
