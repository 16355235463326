/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// want color of icon to be the same for regular/light/dark modes
import React from 'react';

export const AtlassianIntelligenceIcon = ({
	size = 'small',
	greyed = false,
}: {
	size?: 'smaller' | 'small' | 'medium' | 'large';
	greyed?: boolean;
}) => (
	<svg
		width={size === 'smaller' ? '16' : size === 'small' ? '18' : size === 'medium' ? '20' : '24'}
		height={size === 'smaller' ? '16' : size === 'small' ? '18' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M14.5 15.3L17.5 18.3" stroke="#626F86" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M9.5 15.3L6.5 18.3" stroke="#626F86" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M9.5 10.3L6.5 7.30002" stroke="#626F86" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M14.5 10.3L17.5 7.30002" stroke="#626F86" strokeWidth="1.5" strokeLinecap="square" />
		<path d="M12.0001 16.8V19.8" stroke="#626F86" strokeWidth="1.5" />
		<path d="M8.00012 12.8L5.00012 12.8" stroke="#626F86" strokeWidth="1.5" />
		<path d="M12.0001 8.80003L12.0001 5.8" stroke="#626F86" strokeWidth="1.5" />
		<path d="M16.0002 12.8L19.0002 12.8" stroke="#626F86" strokeWidth="1.5" />
	</svg>
);
