import React, { Fragment, lazy, Suspense, useCallback, useState } from 'react';

import { useIntl } from 'react-intl-next';

import Checkbox from '@atlaskit/checkbox';
import { FlagGroup } from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/utility/migration/information--info';
import Link from '@atlaskit/link';
import Lozenge from '@atlaskit/lozenge';
import { fg } from '@atlaskit/platform-feature-flags';
import { Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import AtlassianIntelligenceIcon from '../../atlassian-intelligence-icon';
import {
	AiBrand,
	ATLASSIAN_INTELLIGENCE_TRUST_LINK,
	ATLASSIAN_ROVO_LINK,
	CardTemplate,
	FEEDBACK_CONTEXT_CF,
	FEEDBACK_ENTRYPOINT_ID_SAIN,
} from '../../constants';
import { messages } from '../../messages';
import { RovoIcon } from '../../rovo-icon';
import { DefinitionFooter } from '../../v2/definitions/footer/definition-footer';
import { AIAnswerWhoFooter } from '../ai-answer-body/ai-answer-result';
import { useAIAnswerContext } from '../ai-answer-context';
import { NLPSearchErrorState, NLPSearchResultFormat } from '../types';

import { FeedbackButtons } from './feedback-buttons';
import { FollowUpSuggestionFooter } from './followup-suggestion-footer';
import {
	AIAnswerFeedbackWrapper,
	AIAnswerFooterInformationContentVary,
	AIAnswerFooterInformationContentVaryLinkText,
	AIAnswerFooterPoweredBy,
	AIAnswerFooterPoweredByAIWrapper,
	AIAnswerFooterWrapper,
	AIAnswerReportDescriptionWrapper,
} from './styled';

const AkFeedbackCollector = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/feedback-collector" */ '@atlaskit/feedback-collector'
	).then((module) => {
		return {
			default: module.default,
		};
	}),
);

const AkFeedbackFlag = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/feedback-flag" */ '@atlaskit/feedback-collector'
	).then(({ FeedbackFlag }) => {
		return {
			default: FeedbackFlag,
		};
	}),
);

export const SmartAnswerFooterSection = () => {
	const { formatMessage } = useIntl();
	const { cardTemplate, brand } = useAIAnswerContext();
	return (
		<AIAnswerFooterWrapper>
			<AIAnswerFooterInformationContentVary>
				{brand === AiBrand.ROVO && fg('platform_search_ai_rovo_beta_label_off') ? null : (
					<Lozenge>{formatMessage(messages.ai_answer_component_beta_status_label)}</Lozenge>
				)}
				<InfoIcon label="" color={token('color.icon.subtle', '#626F86')} LEGACY_size="small" />
				<AIAnswerFooterInformationContentVaryLinkText>
					<Link
						rel="noopener noreferrer"
						appearance="subtle"
						href={ATLASSIAN_INTELLIGENCE_TRUST_LINK}
					>
						{formatMessage(
							cardTemplate === CardTemplate.RIGHT_PANEL
								? messages.ai_answer_dialog_footer_content_quality_short
								: messages.ai_answer_dialog_footer_content_quality_long,
						)}
					</Link>
				</AIAnswerFooterInformationContentVaryLinkText>
			</AIAnswerFooterInformationContentVary>
			<AIAnswerFooterPoweredBy>
				{brand === AiBrand.ROVO ? (
					<>
						<Link rel="noopener noreferrer" appearance="subtle" href={ATLASSIAN_ROVO_LINK}>
							Rovo
						</Link>
						<RovoIcon label="" />
					</>
				) : (
					<AIAnswerFooterPoweredByAIWrapper>
						{formatMessage(messages.ai_answer_dialog_loaded_footer)}
						<AtlassianIntelligenceIcon greyed size={'large'} />
					</AIAnswerFooterPoweredByAIWrapper>
				)}
			</AIAnswerFooterPoweredBy>
		</AIAnswerFooterWrapper>
	);
};

type SearchPageSmartAnswerFooterProps = {
	showFollowups?: boolean;
};
export const SearchPageSmartAnswerFooter = ({
	showFollowups = true,
}: SearchPageSmartAnswerFooterProps) => {
	const { formatMessage, locale } = useIntl();
	const {
		commonAttributes,
		userDetails: { name, id },
		readingAidsOptions,
		loading,
		isAdminHubAIEnabled,
	} = useAIAnswerContext();
	const { errorState } = commonAttributes;

	const [showFeedbackModal, setFeedbackModal] = useState(false);
	const [displayFlag, setDisplayFlag] = useState(false);
	const [hasConsent, setConsent] = useState(false);
	const [topicsNotRelevant, setTopicsNotRelevant] = useState(false);

	const feedbackContextObject = {
		source: commonAttributes.source,
		queryHash: commonAttributes.queryHash,
		cloudId: commonAttributes.cloudId,
		prompt: hasConsent ? commonAttributes.query : undefined,
		answer: hasConsent ? commonAttributes.answerString : undefined,
		documentId: readingAidsOptions?.contentARI,
		errorState: errorState,
		topicsNotRelevant: topicsNotRelevant ? true : undefined,
	};
	const feedbackContext = Object.entries(feedbackContextObject)
		.map(([key, value]) => `${key}: ${value}`)
		.join(', ');

	const toggleConsentCheckbox = useCallback(() => {
		setConsent(!hasConsent);
	}, [hasConsent]);
	const toggleTopicsNotRelevantCheckbox = useCallback(() => {
		setTopicsNotRelevant(!topicsNotRelevant);
	}, [topicsNotRelevant]);

	const topicsEnabled = isAdminHubAIEnabled && fg('ai_topics_surface_person_knowledge_card');

	return (
		<Fragment>
			{showFollowups && <FollowUpSuggestionFooter />}
			{!loading && (
				<AIAnswerFeedbackWrapper isCompact>
					<FeedbackButtons setDisplayFlag={setDisplayFlag} setFeedbackModal={setFeedbackModal} />
				</AIAnswerFeedbackWrapper>
			)}
			<SmartAnswerFooterSection />
			{showFeedbackModal ? (
				<Suspense fallback={<></>}>
					<AkFeedbackCollector
						locale={locale}
						onClose={() => setFeedbackModal(false)}
						onSubmit={() => {
							setDisplayFlag(true);
						}}
						customContent={
							<Stack space="space.050">
								<Checkbox
									label={formatMessage(messages.ai_answer_component_report_consent_checkbox_label)}
									onChange={toggleConsentCheckbox}
									isChecked={hasConsent}
								/>
								{topicsEnabled && (
									<Checkbox
										label={formatMessage(
											messages.ai_answer_component_topics_not_relevant_checkbox_label,
										)}
										onChange={toggleTopicsNotRelevantCheckbox}
										isChecked={topicsNotRelevant}
									/>
								)}
							</Stack>
						}
						atlassianAccountId={id}
						name={name}
						entrypointId={FEEDBACK_ENTRYPOINT_ID_SAIN}
						additionalFields={[
							{
								id: FEEDBACK_CONTEXT_CF,
								value: feedbackContext,
							},
						]}
						feedbackTitle={formatMessage(messages.ai_answer_component_report_modal_title)}
						feedbackTitleDetails={
							<AIAnswerReportDescriptionWrapper>
								{formatMessage(messages.ai_answer_component_report_summary_label)}
							</AIAnswerReportDescriptionWrapper>
						}
						feedbackGroupLabels={{
							empty: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_empty_option_share_label,
								),
							},
							question: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_relevance_option_label,
								),
							},
							comment: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_inaccurate_option_label,
								),
							},
							bug: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_offensive_option_label,
								),
							},
							suggestion: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_other_option_label,
								),
							},
						}}
						summaryPlaceholder={formatMessage(
							messages.ai_answer_component_summary_placeholder_label,
						)}
					/>
				</Suspense>
			) : null}
			{displayFlag && (
				<FlagGroup onDismissed={() => setDisplayFlag(false)}>
					<Suspense fallback={<></>}>
						<AkFeedbackFlag
							locale={locale}
							description={formatMessage(messages.ai_answer_component_feedback_flag_description)}
						/>
					</Suspense>
				</FlagGroup>
			)}
		</Fragment>
	);
};

const AIAnswerDialogFooter = () => {
	const { formatMessage, locale } = useIntl();
	const {
		commonAttributes,
		isAnswered,
		loading,
		userDetails: { name, id },
		isAdminHubAIEnabled,
	} = useAIAnswerContext();
	const { answerFormat, errorState } = commonAttributes;

	const [showFeedbackModal, setFeedbackModal] = useState(false);
	const [displayFlag, setDisplayFlag] = useState(false);
	const [hasConsent, setConsent] = useState(false);
	const [topicsNotRelevant, setTopicsNotRelevant] = useState(false);

	const feedbackContextObject = {
		searchSessionId: commonAttributes.searchSessionId,
		source: commonAttributes.source,
		queryHash: commonAttributes.queryHash,
		cloudId: commonAttributes.cloudId,
		prompt: hasConsent ? commonAttributes.query : undefined,
		answer: hasConsent ? commonAttributes.answerString : undefined,
		errorState: commonAttributes.errorState,
		topicsNotRelevant: topicsNotRelevant ? true : undefined,
	};
	const feedbackContext = Object.entries(feedbackContextObject)
		.map(([key, value]) => `${key}: ${value}`)
		.join(', ');

	const toggleConsentCheckbox = useCallback(() => {
		setConsent(!hasConsent);
	}, [hasConsent]);
	const toggleTopicsNotRelevantCheckbox = useCallback(() => {
		setTopicsNotRelevant(!topicsNotRelevant);
	}, [topicsNotRelevant]);

	const hasError =
		loading !== undefined && !!errorState && errorState !== NLPSearchErrorState.Default;

	const topicsEnabled = isAdminHubAIEnabled && fg('ai_topics_surface_person_knowledge_card');

	const noAnswer = !isAnswered && hasError;
	return (
		<Fragment>
			{answerFormat === NLPSearchResultFormat.JSON && <AIAnswerWhoFooter />}
			<FollowUpSuggestionFooter />
			{noAnswer ? null : (
				<AIAnswerFeedbackWrapper isCompact>
					<FeedbackButtons setDisplayFlag={setDisplayFlag} setFeedbackModal={setFeedbackModal} />
				</AIAnswerFeedbackWrapper>
			)}
			<SmartAnswerFooterSection />
			{showFeedbackModal ? (
				<Suspense fallback={<></>}>
					<AkFeedbackCollector
						locale={locale}
						onClose={() => setFeedbackModal(false)}
						onSubmit={() => {
							setDisplayFlag(true);
						}}
						customContent={
							<Stack space="space.050">
								<Checkbox
									label={formatMessage(messages.ai_answer_component_report_consent_checkbox_label)}
									onChange={toggleConsentCheckbox}
									isChecked={hasConsent}
								/>
								{topicsEnabled && (
									<Checkbox
										label={formatMessage(
											messages.ai_answer_component_topics_not_relevant_checkbox_label,
										)}
										onChange={toggleTopicsNotRelevantCheckbox}
										isChecked={topicsNotRelevant}
									/>
								)}
							</Stack>
						}
						atlassianAccountId={id}
						name={name}
						entrypointId={FEEDBACK_ENTRYPOINT_ID_SAIN}
						additionalFields={[
							{
								id: FEEDBACK_CONTEXT_CF,
								value: feedbackContext,
							},
						]}
						feedbackTitle={formatMessage(messages.ai_answer_component_report_modal_title)}
						feedbackTitleDetails={
							<AIAnswerReportDescriptionWrapper>
								{formatMessage(messages.ai_answer_component_report_summary_label)}
							</AIAnswerReportDescriptionWrapper>
						}
						feedbackGroupLabels={{
							empty: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_empty_option_label,
								),
							},
							question: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_relevance_option_label,
								),
							},
							comment: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_inaccurate_option_label,
								),
							},
							bug: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_offensive_option_label,
								),
							},
							suggestion: {
								fieldLabel: formatMessage(messages.ai_answer_component_report_details_label),
								selectOptionLabel: formatMessage(
									messages.ai_answer_component_report_other_option_label,
								),
							},
						}}
						summaryPlaceholder={formatMessage(
							messages.ai_answer_component_summary_placeholder_label,
						)}
					/>
				</Suspense>
			) : null}
			{displayFlag && (
				<FlagGroup onDismissed={() => setDisplayFlag(false)}>
					<Suspense fallback={<></>}>
						<AkFeedbackFlag
							locale={locale}
							description={formatMessage(messages.ai_answer_component_feedback_flag_description)}
						/>
					</Suspense>
				</FlagGroup>
			)}
		</Fragment>
	);
};

export const AIAnswerFooter = () => {
	const { isReadingAids, cardTemplate } = useAIAnswerContext();
	switch (cardTemplate) {
		case CardTemplate.SMART_ANSWER:
		case CardTemplate.RIGHT_PANEL:
			return <SearchPageSmartAnswerFooter />;
		default:
			return isReadingAids ? <DefinitionFooter /> : <AIAnswerDialogFooter />;
	}
};
