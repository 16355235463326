import { type NLPSearchType } from '../../../ai-answer-dialog/types';

export enum EditedDefinitionScope {
	SPACE = 'SPACE',
	PAGE = 'PAGE',
	BLOGPOST = 'BLOGPOST',
	ORGANIZATION = 'ORGANIZATION',
	PROJECT = 'PROJECT',
}

export type CreateDefinitionFunctionArgs = {
	keyPhrase: string;
	editedDefinition: string;
	scope: EditedDefinitionScope;
	referenceContentId: string | null;
	referenceUrl: string | null;
};

export type CreateDefinitionFunctionType = ({
	keyPhrase,
	editedDefinition,
	scope,
	referenceContentId,
	referenceUrl,
}: CreateDefinitionFunctionArgs) => Promise<NLPSearchType>;

export type CuratedDefinitionSourceType = {
	id: string;
	type: string;
	title: string;
	container: string;
	iconUrl: string;
	url: string;
	lastModified?: string;
};

export type CuratedDefinitionSearchSourcesFunctionType = (
	query: string,
) => Promise<CuratedDefinitionSourceType[]>;

export type RestrictionOptionType = {
	label: string;
	value: EditedDefinitionScope;
	icon: React.ReactNode;
};

export type ConfluenceScopeType = {
	contentARI: string;
	spaceARI: string;
};

export type JiraScopeType = {
	projectARI: string;
};

export type CuratedDefinitionScopeConfigType = {
	workspaceId: string;
	definitionRestrictionOptions: RestrictionOptionType[];
	productScopes: ConfluenceScopeType | JiraScopeType;
};

export type CuratedDefinitionOptions = {
	curatedDefinitionScopeConfig: CuratedDefinitionScopeConfigType;
	editModalShownRef?: React.MutableRefObject<boolean>;
	// deprecated
	showHistoryModal?: () => void;
};
