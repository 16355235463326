import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title_label: {
		id: 'search_ai.smart_answer_card_title',
		defaultMessage: 'Overview',
		description: 'Label text for the smart answer card',
	},
	expand_label: {
		id: 'search_ai.smart_answer_expand_label',
		defaultMessage: 'Expand',
		description: 'Label text for the button that expands a smart answer card',
	},
	collapse_label: {
		id: 'search_ai.smart_answer_collapse_label',
		defaultMessage: 'Collapse',
		description: 'Label text for the button that collapses a smart answer card',
	},
	ai_answer_generating_icon_alt_text: {
		id: 'search_ai.ai_answer_generating_icon_alt_text',
		defaultMessage: 'Generating icon',
		description: 'Alt text of the generating icon',
	},
	ai_answer_generating_rovo: {
		id: 'ai_answer_generating_rovo',
		defaultMessage: 'Generating',
		description: 'Rovo text for loading state of smart answer ',
	},
	ai_answer_expand_tooltip: {
		id: 'search_ai.ai_answer_expand_tooltip',
		defaultMessage: 'Expand',
		description: 'Tooltip text to expand the ai answer component',
	},
	ai_answer_collapse_tooltip: {
		id: 'search_ai.ai_answer_collapse_tooltip',
		defaultMessage: 'Collapse',
		description: 'Tooltip text to collapse the ai answer component',
	},
});
