/* eslint @atlaskit/ui-styling-standard/enforce-style-prop: 0 */
import React, { useCallback, useEffect, useRef } from 'react';

import { bind } from 'bind-event-listener';
import ReactDOM from 'react-dom';

const MAX_ANIMATION_PROGRESS = 100;
const ANIMATION_DURATION = 2500;
const CANVAS_PADDING = 8;
let animationRequestId: number = 0;

const DOM_PORTAL_KEY = 'search-ai-loading-animation-portal';

const bezierFn = (
	c1: number,
	c2: number,
	t: number,
	xscale: number,
	yscale: number,
	xtranslate: number,
	ytranslate: number,
) => {
	const z = (1 / xscale) * (t - xtranslate);
	return (
		yscale *
			(3 * c1 * Math.pow(1 - z, 2) * z + 3 * c2 * (1 - z) * Math.pow(z, 2) + Math.pow(z, 3)) +
		ytranslate
	);
};

const easeInOutTiming = (timeFraction: number) => {
	const c1 = 0.05;
	const c2 = 0.95;
	if (timeFraction < 0.5) {
		return bezierFn(c1, c2, timeFraction, 0.5, 0.5, 0, 0);
	} else {
		return bezierFn(c1, c2, timeFraction, 0.5, 0.5, 0.5, 0.5);
	}
};

export type SelectionRectType = {
	top: number;
	left: number;
	width: number;
	height: number;
};

export type LoadingAnimationProps = {
	selectionRect: SelectionRectType;
	documentRef: React.MutableRefObject<HTMLDivElement | null>;
	darkModeEnabled?: boolean;
	handleResize?: () => void;
	onAnimationShown?: () => void;
};

export const LoadingAnimation = React.memo(
	({
		selectionRect,
		documentRef,
		darkModeEnabled = false,
		handleResize,
		onAnimationShown,
	}: LoadingAnimationProps) => {
		const canvasRef = useRef<HTMLCanvasElement>(null);
		const windowWidth = useRef<number>(window.innerWidth).current;
		const windowHeight = useRef<number>(window.innerHeight).current;
		const topOffset = selectionRect.top - (documentRef.current?.getBoundingClientRect().top ?? 0);
		const leftOffset =
			selectionRect.left - (documentRef.current?.getBoundingClientRect().left ?? 0);
		const startTime = useRef(performance.now()).current;

		const handleResizeCallback = useCallback(
			(event: Event) => {
				if (windowWidth === window.innerWidth && windowHeight === window.innerHeight) {
					return;
				}
				handleResize?.();
			},
			[windowWidth, windowHeight, handleResize],
		);

		const draw = useCallback(
			(animationProgress: number) => {
				const canvas = canvasRef.current;
				const ctx = canvasRef.current?.getContext('2d');
				if (canvas && ctx) {
					ctx.clearRect(0, 0, selectionRect.width, selectionRect.height);

					const canvasWidth = selectionRect.width + CANVAS_PADDING;
					const canvasHeight = selectionRect.height + CANVAS_PADDING;
					canvas.setAttribute('height', (canvasHeight * window.devicePixelRatio).toString());
					canvas.setAttribute('width', (canvasWidth * window.devicePixelRatio).toString());

					const startX = CANVAS_PADDING / 2;
					const midX = startX + selectionRect.width / 2;
					const endX = startX + selectionRect.width;
					const midY = CANVAS_PADDING / 2 + selectionRect.height / 2;
					const leftHeightRatio = 0.4;
					const midHeightRatio = 0.16;
					const rightHeightRatio = 0.04;
					const progressOffsetRatio =
						animationProgress < MAX_ANIMATION_PROGRESS / 2
							? (leftHeightRatio - rightHeightRatio) *
								(animationProgress / (MAX_ANIMATION_PROGRESS / 2))
							: (leftHeightRatio - rightHeightRatio) *
								((MAX_ANIMATION_PROGRESS - animationProgress) / (MAX_ANIMATION_PROGRESS / 2));
					const rightOffset = selectionRect.height * rightHeightRatio;
					const midOffset = selectionRect.height * midHeightRatio;
					const leftOffset = selectionRect.height * leftHeightRatio;
					const progressOffset = selectionRect.height * progressOffsetRatio;
					const innerCurveOffsetRatio = 0.15;
					const innerCurveOffset = selectionRect.width * innerCurveOffsetRatio;
					const outerCurveOffsetRatio = 0.05;
					const outerCurveOffset = selectionRect.width * outerCurveOffsetRatio;
					const heightCurveOffsetRatio = 0.1;
					const heightCurveOffset = selectionRect.height * heightCurveOffsetRatio;

					const gradient = ctx.createLinearGradient(startX, midY, endX, midY);
					if (darkModeEnabled) {
						gradient.addColorStop(0, 'rgba(0, 101, 255, 0.7)');
						gradient.addColorStop(0.5, 'rgba(191, 99, 243, 0.5)');
						gradient.addColorStop(1, 'rgba(245, 205, 71, 0.4)');
					} else {
						gradient.addColorStop(0, 'rgba(0, 101, 255, 0.45)');
						gradient.addColorStop(0.5, 'rgba(191, 99, 243, 0.45)');
						gradient.addColorStop(1, 'rgba(245, 205, 71, 0.45)');
					}

					ctx.fillStyle = gradient;
					ctx.strokeStyle = gradient;
					ctx.imageSmoothingEnabled = false;
					ctx.filter = 'blur(2.5px)';

					ctx.beginPath();
					ctx.moveTo(midX, midY + midOffset);
					ctx.quadraticCurveTo(
						endX - innerCurveOffset,
						midY + rightOffset + progressOffset + heightCurveOffset,
						endX,
						midY + rightOffset + progressOffset,
					);
					ctx.quadraticCurveTo(
						endX + outerCurveOffset,
						midY,
						endX,
						midY - rightOffset - progressOffset,
					);
					ctx.quadraticCurveTo(
						endX - innerCurveOffset,
						midY - rightOffset - progressOffset - heightCurveOffset,
						midX,
						midY - midOffset,
					);
					ctx.quadraticCurveTo(
						startX + innerCurveOffset,
						midY - leftOffset + progressOffset - heightCurveOffset,
						startX,
						midY - leftOffset + progressOffset,
					);
					ctx.quadraticCurveTo(
						startX - outerCurveOffset,
						midY,
						startX,
						midY + leftOffset - progressOffset,
					);
					ctx.quadraticCurveTo(
						startX + innerCurveOffset,
						midY + leftOffset - progressOffset + heightCurveOffset,
						midX,
						midY + midOffset,
					);
					ctx.stroke();
					ctx.fill();
				}
			},
			[canvasRef, selectionRect, darkModeEnabled],
		);

		const animate = useCallback(
			(time: number) => {
				const ctx = canvasRef.current?.getContext('2d');
				if (!ctx) {
					return;
				}
				const timeFraction = ((time - startTime) / ANIMATION_DURATION) % 1;
				const bezierTiming = easeInOutTiming(timeFraction);
				const animationProgress = bezierTiming * MAX_ANIMATION_PROGRESS;
				draw(animationProgress);
				animationRequestId = requestAnimationFrame(animate);
			},
			[canvasRef, draw, startTime],
		);

		useEffect(() => {
			window.getSelection()?.removeAllRanges();
		}, []);

		useEffect(() => {
			const unbind = bind(window, {
				type: 'resize',
				listener: handleResizeCallback,
			});
			return () => {
				unbind();
			};
		}, [handleResizeCallback]);

		useEffect(() => {
			onAnimationShown?.();
			animationRequestId = requestAnimationFrame(animate);
			return () => {
				cancelAnimationFrame(animationRequestId);
			};
		}, [selectionRect, animate, onAnimationShown]);

		if (documentRef.current === null) {
			return null;
		}

		return ReactDOM.createPortal(
			<span
				style={{
					position: 'absolute',
					overflow: 'hidden',
					cursor: 'wait',
					top: `${topOffset - CANVAS_PADDING / 2}px`,
					left: `${leftOffset - CANVAS_PADDING / 2}px`,
					width: `${selectionRect.width + CANVAS_PADDING}px`,
					height: `${selectionRect.height + CANVAS_PADDING}px`,
				}}
				data-testid="loading-animation"
			>
				<canvas
					ref={canvasRef}
					width={selectionRect.width + CANVAS_PADDING}
					height={selectionRect.height + CANVAS_PADDING}
				/>
			</span>,
			documentRef.current,
			DOM_PORTAL_KEY,
		);
	},
);
