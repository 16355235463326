import React, { type FC, Fragment, useEffect } from 'react';

import { metrics } from '@atlassian/browser-metrics';

import { useAIAnswerContext } from './ai-answer-context';

/**
 * @deprecated use 'aiAnswerDialogMetric' from common instead
 */
export const aiAnswerDialogMetric = metrics.pageSegmentLoad({
	key: 'ai-search-dialog',
}); // Product is automatically prepended to the key

/**
 * @deprecated use 'MarkAIAnswerDialogMetricEnd' from common instead
 */
export const MarkAIAnswerDialogMetricEnd: FC = () => {
	const { isReadingAids, commonAttributes } = useAIAnswerContext();
	const { source } = commonAttributes;
	useEffect(() => {
		aiAnswerDialogMetric.stop({
			customData: { isReadingAids: !!isReadingAids, source },
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <Fragment />;
};
