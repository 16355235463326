import { type ActivityItem } from '@atlassian/recent-work-client';

import { type NLPSearchWorkResultRecentActivityDataType } from '../ai-answer-dialog/types';
import { logger } from '../common';

export const onEmailCopy = (email: string) => {
	// try catch is for the clipboard function
	try {
		void navigator.clipboard.writeText(email);
	} catch (error) {
		logger.error(error);
	}
};

export function trimARI(ari: string): string {
	return ari.split('/').pop() || '';
}

const MAX_CONTRIBUTORS = 3;
export const sanitizeAndMapRecentWorkActivity = (recentWork: ActivityItem[]) => {
	const uniqRecents = new Set();

	return recentWork.reduce<NLPSearchWorkResultRecentActivityDataType[]>(
		(result, recentWorkItem) => {
			const containerName =
				recentWorkItem.containers.length > 0
					? recentWorkItem.containers[0].name
					: recentWorkItem.object.product;
			// creating a key with the container name and title to
			// prevent the posibilty of multiple items with the same name
			const key = `${containerName}:${recentWorkItem.object.name || ''}`;
			if (uniqRecents.has(key)) {
				return result;
			}

			uniqRecents.add(key);
			const curRecentWorkItem = {
				title: recentWorkItem.object.name || '',
				url: recentWorkItem.object.url,
				containerName,
				event: recentWorkItem.eventType as NLPSearchWorkResultRecentActivityDataType['event'],
				timestamp: recentWorkItem.timestamp,
				iconUrl: !!recentWorkItem.object.icon ? recentWorkItem.object.icon.value : '',
				contributors: recentWorkItem.contributors.slice(0, 3).map((contributor) => ({
					name: contributor.name,
					id: contributor.accountId,
				})),
				othersCount:
					recentWorkItem.contributors.length > MAX_CONTRIBUTORS
						? recentWorkItem.contributors.length - MAX_CONTRIBUTORS
						: 0,
			};

			return result.concat(curRecentWorkItem);
		},
		[],
	);
};
