import { useEffect, useState } from 'react';

import { fetchAggResponse } from '../../../../common';
import {
	DefinitionEditHistoryGQL,
	type DefinitionEditHistoryQueryType,
	type DefinitionEditHistoryQueryVariables,
} from '../../gql/edit-history-query';
import { type CuratedDefinitionScopeConfigType } from '../types';

import { getProductScope } from './utils';

export const useDefinitionEditHistory = ({
	query,
	curatedDefinitionScopeConfig,
}: {
	query: string;
	curatedDefinitionScopeConfig: CuratedDefinitionScopeConfigType;
}) => {
	const [data, setData] = useState<DefinitionEditHistoryQueryType | undefined>(undefined);
	const [error, setError] = useState<any>(undefined);
	const loading = data === undefined && error === undefined;
	const scopeIds = getProductScope(curatedDefinitionScopeConfig.productScopes);

	useEffect(() => {
		const fireRequest = async () => {
			try {
				const res = await fetchAggResponse<
					DefinitionEditHistoryQueryVariables,
					DefinitionEditHistoryQueryType
				>({
					graphQuery: DefinitionEditHistoryGQL.query,
					operationName: DefinitionEditHistoryGQL.operationName,
					variables: {
						workspaceId: curatedDefinitionScopeConfig.workspaceId,
						keyPhrase: query,
						...scopeIds,
					},
				});
				const { data, errors } = res;
				if (!errors && !!data) {
					setData(data);
				} else {
					throw new Error(errors?.map((e) => e.message).join(', '));
				}
			} catch (e: any) {
				setError(e);
			}
		};
		fireRequest();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const definitions =
		!error && !data?.knowledgeDiscovery?.definitionHistory?.message
			? data?.knowledgeDiscovery?.definitionHistory?.definitions || []
			: [];

	return {
		definitions,
		loading,
	};
};
