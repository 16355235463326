import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import { type IntlShape } from 'react-intl-next';

import {
	otherSingularActivityMessages,
	pluralActivityMessages,
	youSingularActivityMessages,
} from './messages';

type descriptionHelperArgs = {
	currentUserId: string;
	event: string;
	contributors: { id: string; name: string }[];
	othersCount: number;
	timestamp: Date;
	formatMessage: IntlShape['formatMessage'];
	formatDate: IntlShape['formatDate'];
};

export const descriptionHelper = ({
	currentUserId,
	event,
	contributors,
	othersCount,
	timestamp,
	formatMessage,
	formatDate,
}: descriptionHelperArgs): string => {
	const involvesCurrentUser = contributors.some((contributor) => contributor.id === currentUserId);

	// If the event is singular with a single contributor
	if (contributors.length === 1) {
		const formattedDate = formatDate(timestamp, {
			month: 'long',
			day: 'numeric',
			year: 'numeric',
		});

		let message: any;
		switch (event) {
			case 'created':
				message = involvesCurrentUser
					? youSingularActivityMessages.created
					: otherSingularActivityMessages.created;
				break;
			case 'edited':
				message = involvesCurrentUser
					? youSingularActivityMessages.edited
					: otherSingularActivityMessages.edited;
				break;
			case 'commented':
				message = involvesCurrentUser
					? youSingularActivityMessages.commented
					: otherSingularActivityMessages.commented;
				break;
			case 'updated':
				message = involvesCurrentUser
					? youSingularActivityMessages.updated
					: otherSingularActivityMessages.updated;
				break;
			case 'worked':
			default:
				message = involvesCurrentUser
					? youSingularActivityMessages.worked
					: otherSingularActivityMessages.worked;
				break;
		}

		if (isToday(timestamp)) {
			return involvesCurrentUser
				? formatMessage(message.today)
				: formatMessage(message.today, { name: contributors[0].name });
		} else if (isYesterday(timestamp)) {
			return involvesCurrentUser
				? formatMessage(message.yesterday)
				: formatMessage(message.yesterday, { name: contributors[0].name });
		} else {
			return involvesCurrentUser
				? formatMessage(message.otherTime, { time: formattedDate })
				: formatMessage(message.yesterday, {
						name: contributors[0].name,
						time: formattedDate,
					});
		}
	}
	// If the activity is plural with more than one contributor
	else {
		if (involvesCurrentUser) {
			const contributorsWithoutCurrentUser = contributors.filter(
				(contributor) => contributor.id !== currentUserId,
			);
			if (othersCount > 0) {
				return formatMessage(pluralActivityMessages.you.many, {
					name1: contributorsWithoutCurrentUser[0].name,
					count: othersCount,
				});
			} else {
				return contributors.length === 2
					? formatMessage(pluralActivityMessages.you.double, {
							name1: contributorsWithoutCurrentUser[0].name,
						})
					: formatMessage(pluralActivityMessages.you.triple, {
							name1: contributorsWithoutCurrentUser[0].name,
							name2: contributorsWithoutCurrentUser[1].name,
						});
			}
		} else {
			if (othersCount > 0) {
				return formatMessage(pluralActivityMessages.other.many, {
					name1: contributors[0].name,
					name2: contributors[1].name,
					count: othersCount,
				});
			} else {
				return contributors.length === 2
					? formatMessage(pluralActivityMessages.other.double, {
							name1: contributors[0].name,
							name2: contributors[1].name,
						})
					: formatMessage(pluralActivityMessages.other.triple, {
							name1: contributors[0].name,
							name2: contributors[1].name,
							name3: contributors[2].name,
						});
			}
		}
	}
};
