import React from 'react';

import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

const CustomGlyph = (props: CustomGlyphProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.89543 16 2V10C16 11.1046 15.1046 12 14 12H2C0.895431 12 0 11.1046 0 10V2ZM2 1.5C1.72386 1.5 1.5 1.72386 1.5 2V2.52859L8 6.12798L14.5 2.52859V2C14.5 1.72386 14.2761 1.5 14 1.5H2ZM14.5 4.24322L8.36333 7.64141C8.13728 7.76659 7.86272 7.76659 7.63667 7.64141L1.5 4.24322V10C1.5 10.2761 1.72386 10.5 2 10.5H14C14.2761 10.5 14.5 10.2761 14.5 10V4.24322Z"
			fill="#44546F"
		/>
	</svg>
);

export const EmailIcon = (props: Omit<IconProps, 'glyph'>) => (
	<Icon glyph={CustomGlyph} primaryColor={token('color.icon.brand')} {...props} />
);
