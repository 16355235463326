export interface SearchSourceContentNode {
	id: string;
	type: string;
	title: string;
	url: string;
	iconUrl: string | null;
	lastModified: string | null;
	space: {
		name: string | null;
	} | null;
}

export interface SearchSourceQueryType {
	search: {
		results: {
			edges: {
				node: SearchSourceContentNode | null;
			}[];
		};
	};
}

export interface SearchSourceQueryVariables {
	experience: string;
	query: string;
	analytics: {
		searchSessionId: string;
	};
	filters: {
		entities: string[];
		locations: string[];
		confluenceFilters: {
			titleMatchOnly: boolean;
		};
	};
	first: number;
}

export const SearchSourceQuery = `
	query ReadingAidsReferenceSearchQuery(
			$experience: String!
			$analytics: SearchAnalyticsInput
			$query: String!
			$filters: SearchFilterInput!
			$first: Int
		) {
		search {
			results: search(
				experience: $experience
				analytics: $analytics
				query: $query
				filters: $filters
				first: $first
			) {
				edges {
					node {
						type
						... on SearchConfluencePageBlogAttachment {
							id
							title
							url
							iconUrl
							lastModified
							space {
								name
							}
						}
					}
				}
			}
		}
	}
`;
