import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';

import { type commonAttributesType } from '../ai-answer-dialog/types';
import { filterCommonAttributes } from '../common/controller/analytics';

import { type KnowledgeCardsIntentType } from './types';

export const onKnowledgeCardShown = (
	props: commonAttributesType & { cardType: KnowledgeCardsIntentType },
): AnalyticsEventPayload => {
	const { source, cardType } = props;
	return {
		action: 'shown',
		actionSubject: 'aiAnswerDialog',
		actionSubjectId: 'knowledgeCard',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
			cardType,
		},
	};
};

export const onKnowledgeCardLinkClick = (
	props: commonAttributesType,
	location: string,
): AnalyticsEventPayload => {
	const { source } = props;
	return {
		action: 'clicked',
		actionSubject: 'aiAnswerDialog',
		actionSubjectId: 'knowledgeCardLink',
		source: source,
		eventType: 'track',
		attributes: {
			...filterCommonAttributes(props),
			location,
		},
	};
};
