/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { N0, N200, N30, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { dialogWidth, grid } from '@atlassian/search-dialog';

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerDefaultLoadingWrapper = styled.div<{
	isReadingAids?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isReadingAids }) =>
	isReadingAids
		? `
        width: 100%;
				height: 92px;
        padding: ${token('space.300', '24px')};
        box-sizing: border-box;
      `
		: `
		${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        width: ${dialogWidth};
		${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        margin-top: ${grid.px};
        `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerSearchLoadingBorder = styled.div<{
	isStreaming: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isStreaming }) => ({
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
	borderRadius: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: isStreaming ? `${token('space.100')} 0 ${token('space.100')} 0` : '0px',
	boxSizing: 'border-box',
	overflow: 'hidden',
	zIndex: 3,
	boxShadow: token(
		'elevation.shadow.overlay',
		`0 ${token('space.050', '4px')} ${token('space.100', '8px')} ${token(
			'space.negative.025',
			'-2px',
		)} ${N60A}, 0 0 1px ${N60A}`,
	),
	'&::before': {
		content: "''",
		width: '1000vw',
		height: '1000vh',
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
		left: 'calc(-500vw + 50%)',
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
		top: 'calc(-500vh + 50%)',
		position: 'absolute',
		zIndex: -2,
		borderRadius: token('space.050', '4px'),
		background:
			'conic-gradient( from var(--gradient-angle, 45deg), #BF63F3, #FFA900 25%, #0469FF 67%, #0065FF, #BF63F3)',
		animation: 'rotate 2s infinite linear',
		filter: 'blur(25px)',
	},
	'&::after': {
		content: "''",
		width: `100% - ${token('space.050', '4px')}`,
		height: `100% - ${token('space.050', '4px')}`,
		inset: '1px',
		position: 'absolute',
		zIndex: -1,
		borderRadius: token('space.050', '4px'),
		backgroundColor: token('elevation.surface.overlay', N0),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes rotate': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		from: {
			transform: 'rotate(0deg)',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		to: {
			transform: 'rotate(360deg)',
		},
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerLoadingTitleSection = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	width: '100%',
	height: '24px',
	gap: token('space.025', '2px'),
	color: token('color.text.subtlest', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerLoadingSkeletonWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginTop: token('space.200', '16px'),
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerLoadingPill = styled.div({
	display: 'flex',
	width: '100%',
	height: '16px',
	borderRadius: '8px',
	background: token('color.skeleton', N30),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadingAidsLoadingPill = styled.div<{
	width?: string;
	height?: string;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ width, height }) => ({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: width ? width : '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: height ? height : '16px',
	borderRadius: '8px',
	background: `linear-gradient(to right, ${token('color.background.neutral', 'rgba(240, 241, 244, 1)')}, ${token('color.background.input.pressed', 'rgba(252, 252, 252, 1)')})`,
	animation: 'shimmer 1.5s linear infinite alternate',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes shimmer': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		from: {
			background: `linear-gradient(to right, ${token('color.background.neutral', 'rgba(240, 241, 244, 1)')}, ${token('color.background.input.pressed', 'rgba(252, 252, 252, 1)')})`,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		to: {
			background: `linear-gradient(to right, ${token('color.background.neutral', 'rgba(240, 241, 244, 1)')}, ${token('color.background.input.pressed', 'rgba(252, 252, 252, 1)')})`,
		},
	},
}));

// Streaming Markdown Container
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerLoadingStreamMarkdownContainer = styled.div<{
	telepointerId: string;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ telepointerId }) => `
      max-height: 160px;
      overflow-y: auto;
	  padding: ${token('space.100')} ${token('space.300')} ${token('space.0')} ${token('space.300')};
      & .${telepointerId} {
        display: inline-block;
        position: relative;
        width: 1.5px;
        height: 25px;
        background: linear-gradient(
          45deg,
          ${'#F8E6A0'} -12.02%,
          ${'#8BDBE5'} 19.18%,
          ${'#0C66E4'} 71.87%
        );
        margin-left: ${token('space.025', '2px')};

        &::after {
          content: 'AI';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          font-size: 10px;
          font-weight: 700;
          width: 12.5px;
          height: 13px;
          padding-top: 1px;
          padding-left: 1.5px;
          line-height: initial;
          border-radius: 0px 2px 2px 0px;
          color: ${'white'};
          background: linear-gradient(
            45deg,
            ${'#8BDBE5'} -57%,
            ${'#0C66E4'} 71.87%
          );
        }
      }
    `,
);

// copied from /platform/packages/search/search-ai/src/ai-answer-dialog/ai-answer-body/ai-answer-result/styled.tsx
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AIAnswerResultBlurSection = styled.div({
	background: `linear-gradient( 180deg, ${token(
		'utility.UNSAFE.transparent',
		'rgba(255, 255, 255, 0)',
	)} 0%, ${token('elevation.surface.overlay', N0)} 100% )`,
	height: token('space.500', '40px'),
	width: '100%',
	position: 'relative',
	display: 'block',
	margin: `calc(${token('space.500', '40px')} * -1) 1px 0 1px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GeneratingContainer = styled.div({
	display: 'flex',
	height: '92px',
	boxSizing: 'border-box',
	padding: `${token('space.300', '24px')} ${token('space.300', '24px')} 0px ${token('space.300', '24px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GeneratingText = styled.div({
	marginLeft: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', N200),
	fontSize: '14px',
	lineHeight: '20px',
});
