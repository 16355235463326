// vendored from packages/editor/editor-plugin-ai/src/experience-application/screens-with-logic/LoadingScreenWithLogic.tsx
// similar logic also exists in packages/editor/renderer/src/ui/Renderer/index.tsx
import React, { useEffect, useRef, useState } from 'react';

import { AIAnswerLoadingStreamMarkdownContainer, AIAnswerResultBlurSection } from './styled';

const MarkdownRenderer = React.lazy(() =>
	import(/* webpackChunkName: "react-markdown" */ 'react-markdown').then(
		({ default: ReactMarkdown }) => {
			return {
				default: ReactMarkdown,
			};
		},
	),
);

const isScrolledToEnd = (element: HTMLElement | null) => {
	return (
		!!element && Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 10
	);
};

const TELEPOINTER_ID = 'ai-streaming-telepointer';

const createTelepointer = () => {
	let newTelepointer = document.createElement('span');
	newTelepointer.textContent = '\u200b';
	newTelepointer.className = TELEPOINTER_ID;
	return newTelepointer;
};

export const AIAnswerLoadingStream = ({ markdown }: { markdown: string }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [showResultBlur, setShowResultBlur] = useState<boolean>(false);
	const initialUpdate = useRef(true);

	useEffect(() => {
		const renderer = containerRef.current!;

		if (initialUpdate.current) {
			const lastChild = renderer.lastChild;
			lastChild && lastChild.appendChild(createTelepointer());
		}

		const mutateTelepointer = (mutations: MutationRecord[]) => {
			mutations.forEach((mutation: MutationRecord) => {
				if (initialUpdate.current) {
					const oldTelepointer = renderer.querySelector(`.${TELEPOINTER_ID}`);
					if (oldTelepointer) {
						oldTelepointer.remove();
					}
					const lastChild = renderer.lastChild;
					lastChild && lastChild.appendChild(createTelepointer());
					initialUpdate.current = false;
				}

				if (mutation.type === 'characterData') {
					const parentNode = mutation.target.parentElement;

					if (parentNode) {
						const oldTelepointer = renderer.querySelector(`.${TELEPOINTER_ID}`);
						if (oldTelepointer) {
							oldTelepointer.remove();
						}
						parentNode!.appendChild(createTelepointer());
					}
				}

				if (containerRef && !showResultBlur && !isScrolledToEnd(containerRef.current)) {
					setShowResultBlur(true);
				}
			});
		};
		const observer = new MutationObserver(mutateTelepointer);

		observer.observe(renderer, {
			characterData: true,
			attributes: false,
			childList: true,
			subtree: true,
		});

		return () => observer.disconnect();
	});

	return (
		<>
			<AIAnswerLoadingStreamMarkdownContainer ref={containerRef} telepointerId={TELEPOINTER_ID}>
				<MarkdownRenderer>{markdown}</MarkdownRenderer>
			</AIAnswerLoadingStreamMarkdownContainer>
			{showResultBlur && <AIAnswerResultBlurSection />}
		</>
	);
};
