import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const Line = xcss({
	height: '1px',
	width: '100%',
	background: token('color.border'),
});

export const SeparatorLine = () => <Box xcss={Line} />;
