import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	recentWorkListTitle: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.title',
		defaultMessage: 'Recently worked on',
		description: 'SAIN recent work list title message',
	},
	viewAllLink: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.view_all_link',
		defaultMessage: 'View all',
		description: 'SAIN recent work list message to view all recently worked on items',
	},
	showMore: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.show_more',
		defaultMessage: 'Show more',
		description: 'SAIN recent work list message to show more recently worked on items',
	},
	no_activity_error_text: {
		id: 'search_ai.ai_answer_dialog.recent_work_list.no_activity_error_text',
		defaultMessage:
			"Atlassian Intelligence doesn’t have enough data to provide a response right now. This may be because {user} is new and they haven't interacted with Confluence yet. In the meantime, try asking a different question.",
		description: 'Empty state text displayed when there is no recent activity for a user',
	},
});
