import React, { type FC, type MouseEvent, useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';

import { useAIAnswerContext } from '../../../../ai-answer-context';
import { onRecentWorkItemClick, onRecentWorkItemContextMenuClick } from '../../../../analytics';

import { descriptionHelper } from './description-helper';
import {
	RecentWorkItemBody,
	RecentWorkItemByline,
	RecentWorkItemContainer,
	RecentWorkItemDescription,
	RecentWorkItemIcon,
	RecentWorkItemLink,
	RecentWorkItemTitle,
	RecentWorkItemWrapper,
} from './styled';

export type RecentWorkItemProps = {
	index: number;
	title: string;
	iconUrl: string;
	url: string;
	container: string;
	event: string;
	contributors: { id: string; name: string }[];
	timestamp: string;
	othersCount: number;
};

export const RecentWorkItem: FC<RecentWorkItemProps> = ({
	index,
	title,
	iconUrl,
	url,
	container,
	event,
	contributors,
	timestamp,
	othersCount,
}: RecentWorkItemProps) => {
	const { formatMessage, formatDate } = useIntl();
	const {
		userDetails: { id: currentUserId },
		fireAnalyticsEvent,
		commonAttributes,
		onNavigate,
	} = useAIAnswerContext();

	const description = descriptionHelper({
		currentUserId: !!currentUserId ? currentUserId : '',
		event,
		contributors,
		othersCount,
		formatMessage,
		formatDate,
		timestamp: new Date(timestamp),
	});

	const onClick = useCallback(
		(event: MouseEvent) => {
			onNavigate(event);
			fireAnalyticsEvent(onRecentWorkItemClick({ index, url, ...commonAttributes }));
		},
		[onNavigate, fireAnalyticsEvent, index, url, commonAttributes],
	);

	const onContextMenuClick = useCallback(
		(event: MouseEvent) => {
			onNavigate(event);
			fireAnalyticsEvent(onRecentWorkItemContextMenuClick({ index, url, ...commonAttributes }));
		},
		[onNavigate, fireAnalyticsEvent, index, url, commonAttributes],
	);

	return (
		<RecentWorkItemLink href={url} onClick={onClick} onContextMenu={onContextMenuClick}>
			<RecentWorkItemWrapper>
				<RecentWorkItemIcon src={iconUrl} alt="" />
				<RecentWorkItemBody>
					<RecentWorkItemTitle>
						<Text as="p">{title}</Text>
					</RecentWorkItemTitle>
					<RecentWorkItemByline>
						<RecentWorkItemContainer>{container}</RecentWorkItemContainer>
						<RecentWorkItemDescription>{description}</RecentWorkItemDescription>
					</RecentWorkItemByline>
				</RecentWorkItemBody>
			</RecentWorkItemWrapper>
		</RecentWorkItemLink>
	);
};
