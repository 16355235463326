import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import SuitcaseIcon from '@atlaskit/icon/core/migration/briefcase--suitcase';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import DepartmentIcon from '@atlaskit/icon/core/migration/department';
import EmailIcon from '@atlaskit/icon/core/migration/email';
import LocationIcon from '@atlaskit/icon/core/migration/location';
import { Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { messages } from '../../../../../messages';

import {
	ProfileSectionPrimaryUserAddtlIconPadding,
	ProfileSectionPrimaryUserInfo,
	ProfileSectionPrimaryUserInfoText,
} from './styled';

export enum UserInfoTypeFormat {
	JOBTITLE = 'JOB_TITLE',
	DEPARTMENT = 'DEPARTMENT',
	LOCATION = 'LOCATION',
	TIME = 'TIME',
	EMAIL = 'EMAIL',
}

type ProfileSectionUserInfoProps = {
	userInfo: string | null;
	userInfoType: UserInfoTypeFormat;
	applyAdditionalTopIconPadding?: boolean;
};

const userInfoTypeMapper = {
	[UserInfoTypeFormat.JOBTITLE]: {
		tooltipMessage: messages.ai_answer_who_questions_hover_role_icon,
		icon: (
			<SuitcaseIcon
				label=""
				LEGACY_size="small"
				color={token('color.text.subtlest', '#626F86')}
				testId="primary-user-suitcase-icon"
			/>
		),
	},
	[UserInfoTypeFormat.DEPARTMENT]: {
		tooltipMessage: messages.ai_answer_who_questions_hover_department_icon,
		icon: (
			<DepartmentIcon
				label=""
				LEGACY_size="small"
				color={token('color.text.subtlest', '#626F86')}
				testId="primary-user-department-icon"
			/>
		),
	},
	[UserInfoTypeFormat.LOCATION]: {
		tooltipMessage: messages.ai_answer_who_questions_hover_location_icon,
		icon: (
			<LocationIcon
				label=""
				LEGACY_size="small"
				color={token('color.text.subtlest', '#626F86')}
				testId="primary-user-location-icon"
			/>
		),
	},
	[UserInfoTypeFormat.TIME]: {
		tooltipMessage: messages.ai_answer_who_questions_hover_local_time_icon,
		icon: (
			<EmojiFrequentIcon
				label=""
				LEGACY_size="small"
				color={token('color.text.subtlest', '#626F86')}
				testId="primary-user-emoji-frequent-icon"
			/>
		),
	},
	[UserInfoTypeFormat.EMAIL]: {
		tooltipMessage: messages.ai_answer_who_questions_hover_email_icon,
		icon: (
			<EmailIcon
				label=""
				LEGACY_size="small"
				color={token('color.text.subtlest', '#626F86')}
				testId="primary-user-email-icon"
			/>
		),
	},
};

export const ProfileSectionUserInfo = ({
	userInfo,
	userInfoType,
	applyAdditionalTopIconPadding,
}: ProfileSectionUserInfoProps) => {
	return userInfo ? (
		<ProfileSectionPrimaryUserInfo>
			<Inline space="space.075" alignBlock="center">
				<Tooltip
					position="top"
					content={<FormattedMessage {...userInfoTypeMapper[userInfoType].tooltipMessage} />}
				>
					{applyAdditionalTopIconPadding ? (
						<ProfileSectionPrimaryUserAddtlIconPadding>
							{userInfoTypeMapper[userInfoType].icon}
						</ProfileSectionPrimaryUserAddtlIconPadding>
					) : (
						userInfoTypeMapper[userInfoType].icon
					)}
				</Tooltip>
				<ProfileSectionPrimaryUserInfoText>{userInfo}</ProfileSectionPrimaryUserInfoText>
			</Inline>
		</ProfileSectionPrimaryUserInfo>
	) : null;
};
