import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	definition_no_answer_error: {
		id: 'search_ai.definition_no_answer_error',
		defaultMessage:
			'{brand} doesn’t have enough data to provide a definition right now. It’s possible that we couldn’t find the relevant information in your company’s knowledge base.',
		description: 'Error message if no definition for the selected term',
	},
	definition_no_answer_acceptable_use_error: {
		id: 'search_ai.definition_no_answer_acceptable_use_error',
		defaultMessage:
			'{brand} can’t provide you with a definition, as it might go against {acceptableUseLink}',
		description: 'Error message if definition has an acceptable use error',
	},
	definition_no_answer_acceptable_use_link: {
		id: 'search_ai.definition_no_answer_acceptable_use_link',
		defaultMessage: "Atlassian's acceptable use policy",
		description: 'Link for acceptable use policy',
	},
	definition_no_answer_network_error: {
		id: 'search_ai.definition_no_answer_network_error',
		defaultMessage: "{brand} can't provide a definition right now. Read more in our {supportLink}",
		description: 'Error message if definition has an error from openAI',
	},
	definitions_support_link_text: {
		id: 'search_ai.definitions_support_link_text',
		defaultMessage: 'support documentation',
		description: 'Link text for support documentation',
	},
	definition_no_answer_add_definition_button: {
		id: 'search_ai.definition_no_answer_add_definition_button',
		defaultMessage: 'Add definition',
		description: 'Button to create definition when no definition is available',
	},
});
