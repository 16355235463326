/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';

import { token } from '@atlaskit/tokens';

// vendored from packages/editor/editor-plugin-ai/src/ui/modal/styles.tsx
const GeneratingAnimation = (props: { alt: string }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<title>{props.alt}</title>
			<circle cx="5.5" cy="12.5" r="1.5" fill={token('color.text.brand', '#0C66E4')}>
				<animate
					attributeName="cy"
					values="11;13;13;13;13;13;13;11"
					dur="1s"
					repeatCount="indefinite"
					begin="0s"
				/>
			</circle>
			<circle cx="11.5" cy="10.5" r="1.5" fill={token('color.text.brand', '#0C66E4')}>
				<animate
					attributeName="cy"
					values="11;13;13;13;13;13;13;11"
					dur="1s"
					repeatCount="indefinite"
					begin="0.2s"
				/>
			</circle>
			<circle cx="17.5" cy="12.5" r="1.5" fill={token('color.text.brand', '#0C66E4')}>
				<animate
					attributeName="cy"
					values="11;13;13;13;13;13;13;11"
					dur="1s"
					repeatCount="indefinite"
					begin="0.4s"
				/>
			</circle>
		</svg>
	);
};

export default GeneratingAnimation;
