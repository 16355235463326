import React from 'react';

import { Box } from '@atlaskit/primitives';

import { AITopicsByPerson } from '../ai-topics';
import { type AITopicsByPersonProps } from '../ai-topics/ai-topics-by-person';

export const KnowledgeCardsTopics = (props: AITopicsByPersonProps) => {
	return (
		<Box>
			<AITopicsByPerson {...props} />
		</Box>
	);
};
