import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	reading_aids_follow_ups_user_initial_query: {
		id: 'search_ai.reading_aids_follow_ups_initial_query',
		defaultMessage: 'Define {term}',
		description:
			'A message to be passed to AI chat from reading aids, this will be shown as if user has asked the bot to define a term',
	},
});
