import React from 'react';

import Icon from '@atlaskit/icon';
import type { CustomGlyphProps } from '@atlaskit/icon/types';
import { B400, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const ViewProfileIconSvg = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="24" height="24" fill={token('color.icon.inverse', N0)} fillOpacity="0.01" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.031 7C10.8952 6.99987 10.7606 7.02661 10.6351 7.07869C10.5097 7.13076 10.3957 7.20715 10.2999 7.30344C10.2041 7.39974 10.1282 7.51404 10.0768 7.63976C10.0253 7.76549 9.99921 7.90015 10 8.036C10.0013 8.31257 10.1117 8.57746 10.3072 8.77312C10.5026 8.96879 10.7674 9.07942 11.044 9.081L14.165 9.095L14.179 12.216C14.1806 12.4926 14.2912 12.7574 14.4869 12.9528C14.6825 13.1483 14.9474 13.2587 15.224 13.26C15.3602 13.2608 15.4952 13.2346 15.6212 13.1829C15.7472 13.1311 15.8616 13.0549 15.958 12.9587C16.0543 12.8624 16.1306 12.748 16.1825 12.6221C16.2343 12.4962 16.2607 12.3612 16.26 12.225L16.241 8.064C16.2389 7.7875 16.1281 7.52291 15.9326 7.32739C15.7371 7.13186 15.4725 7.02109 15.196 7.019L11.035 7H11.031Z"
			fill={token('color.text.accent.blue', B400)}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.364 8.292L6.292 15.363C6.10471 15.5508 5.99954 15.8053 5.99954 16.0705C5.99954 16.3357 6.10471 16.5902 6.292 16.778C6.682 17.168 7.316 17.168 7.707 16.778L14.778 9.707C14.9168 9.56678 15.0112 9.38869 15.0492 9.19506C15.0872 9.00143 15.0672 8.80088 14.9917 8.61857C14.9162 8.43626 14.7886 8.2803 14.6248 8.17026C14.461 8.06022 14.2683 8.00099 14.071 8C13.806 7.99979 13.5517 8.10482 13.364 8.292Z"
			fill={token('color.text.accent.blue', B400)}
		/>
	</svg>
);

export const ViewProfileIcon = () => (
	<Icon glyph={ViewProfileIconSvg} primaryColor={token('color.text.accent.blue', B400)} label="" />
);
